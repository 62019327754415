import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../actions/auth'
import logo from '../cermat.svg'


const Navbar = ({ logout, isAuthenticated, user }) => {
    let username = ''

    if (user) {
        username = user.email
    }

    const [isNavCollapsed, setIsNavCollapsed] = useState(true)
    
    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed)

    const logout_user = () => {
        setIsNavCollapsed(true)
        logout()
    }

    const blankFragment = () => {
        <Fragment></Fragment>
    }

    const loginLink = () => (
        <Fragment>
            <li className="nav-item text-center">
                <Link className="nav-link" aria-current="page" to="/login" onClick={handleNavCollapse}>Login</Link>
            </li>
        </Fragment>
    )

    const signupLink = () => (
        <Fragment>
            <li className="nav-item text-center">
        {/* <Link className="nav-link" aria-current="page" to="/signup" onClick={handleNavCollapse}>Register</Link> */}
            </li>
        </Fragment>
    )

    const retirementLink = () => (
        <Fragment>
            <li className="nav-item text-center">
                <Link className="nav-link" aria-current="page" to="/retirement" onClick={handleNavCollapse}>Retirement</Link>
            </li>
        </Fragment>
    )

    const savingsLink = () => (
        <Fragment>
            <li className="nav-item text-center">
                <Link className="nav-link" aria-current="page" to="/savings" onClick={handleNavCollapse}>Savings</Link>
            </li>
        </Fragment>
    )

    const volatilityLink = () => (
        <Fragment>
            <li className="nav-item text-center">
                <Link className="nav-link" aria-current="page" to="/volatility" onClick={handleNavCollapse}>Volatility</Link>
            </li>
        </Fragment>
    )

    const allocationLink = () => (
        <Fragment>
            <li className="nav-item text-center">
                <Link className="nav-link" aria-current="page" to="/allocation" onClick={handleNavCollapse}>Allocation</Link>
            </li>
        </Fragment>
    )

    const userButton = () => (
        <Fragment>
            <span className="nav-item dropdown">
                <a className="nav-link dropdown-toggle text-center" href="#" role="button" data-bs-toggle="dropdown">{username}</a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li><Link className='dropdown-item text-center' to="/" onClick={logout_user}>Logout</Link></li>
                </ul>
            </span>
        </Fragment>
    )
    

    const guestLinks = () => (
        <Fragment>
            {loginLink()}
            {signupLink()}
        </Fragment>
    )

    const authLinks = () => (
        <Fragment>
            {retirementLink()}
            {savingsLink()}
            {allocationLink()}
            {volatilityLink()}      
        </Fragment>
    )

    return (
        <Fragment>
            <nav className="navbar navbar-expand-md navbar-light bg-light">
            {/* <nav className="navbar navbar-expand-md navbar-dark bg-primary"> */}
                <div className="container-fluid">
                    {/* <Link className="navbar-brand mb-md-1" to='/' style={{ fontWeight: "bold" }}>Cermat</Link> */}

                    <Link className="navbar-brand mx-2" to='/'>
                        <img src={logo} alt="cermat logo" height="16" className="d-inline-block align-baseline"/>
                    </Link>

                    {/* {username ? userButton() : blankFragment()} */}

                    <button
                        className="navbar-toggler mx-2"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded={!isNavCollapsed ? true : false}
                        aria-label="Toggle navigation"
                        onClick={handleNavCollapse}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarSupportedContent">
                        {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0"> */}
                        <ul className="navbar-nav ms-auto">
                            {username ? userButton() : blankFragment()}
                            {isAuthenticated ? authLinks() : guestLinks()}
                        </ul>
                    </div>
                </div>
            </nav>
        </Fragment>
    )

}


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user
})


export default connect(mapStateToProps, { logout })(Navbar)
