import React, { useState, useEffect, Fragment } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout, refresh_token } from '../actions/auth'
import Plot from 'react-plotly.js'


const Allocation = ({ isAuthenticated }) => {
    const [formData, setFormData] = useState({
        toggleSPX: 0,
        toggleTbills: 0,
        toggleUST: 0,
        toggleCorp: 0
    })

    const [sumToggles, setSumToggles] = useState(0)

    const {
        toggleSPX,
        toggleTbills,
        toggleUST,
        toggleCorp
    } = formData

    const [portfolioX, setPortfolioX] = useState(0)
    const [portfolioY, setPortfolioY] = useState(0)

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    useEffect(() => {
        getPortolio()
    }, [formData])

    const data = [
        {
            "Year": "1928",
            "S&P500": "43.81115515",
            "T-Bill": "3.08",
            "UST": "0.835470859",
            "Corp": "3.21955147"
        },
        {
            "Year": "1929",
            "S&P500": "-8.297946612",
            "T-Bill": "3.16",
            "UST": "4.203804156",
            "Corp": "3.01785624"
        },
        {
            "Year": "1930",
            "S&P500": "-25.12363636",
            "T-Bill": "4.55",
            "UST": "4.540931435",
            "Corp": "0.539780946"
        },
        {
            "Year": "1931",
            "S&P500": "-43.83754889",
            "T-Bill": "2.31",
            "UST": "-2.558855962",
            "Corp": "-15.68077508"
        },
        {
            "Year": "1932",
            "S&P500": "-8.642364532",
            "T-Bill": "1.07",
            "UST": "8.79030699",
            "Corp": "23.58960168"
        },
        {
            "Year": "1933",
            "S&P500": "49.98222543",
            "T-Bill": "0.96",
            "UST": "1.855272089",
            "Corp": "12.9668937"
        },
        {
            "Year": "1934",
            "S&P500": "-1.188565697",
            "T-Bill": "0.278333333",
            "UST": "7.963442618",
            "Corp": "18.81642927"
        },
        {
            "Year": "1935",
            "S&P500": "46.74042105",
            "T-Bill": "0.1675",
            "UST": "4.47204773",
            "Corp": "13.30773187"
        },
        {
            "Year": "1936",
            "S&P500": "31.94341028",
            "T-Bill": "0.1725",
            "UST": "5.017875405",
            "Corp": "11.38381587"
        },
        {
            "Year": "1937",
            "S&P500": "-35.33672875",
            "T-Bill": "0.275833333",
            "UST": "1.37914606",
            "Corp": "-4.416191684"
        },
        {
            "Year": "1938",
            "S&P500": "29.28265403",
            "T-Bill": "0.065",
            "UST": "4.213248532",
            "Corp": "9.235881714"
        },
        {
            "Year": "1939",
            "S&P500": "-1.097564688",
            "T-Bill": "0.045833333",
            "UST": "4.412261394",
            "Corp": "7.983137765"
        },
        {
            "Year": "1940",
            "S&P500": "-10.67287319",
            "T-Bill": "0.035833333",
            "UST": "5.402481596",
            "Corp": "8.648137178"
        },
        {
            "Year": "1941",
            "S&P500": "-12.77145558",
            "T-Bill": "0.129166667",
            "UST": "-2.022197585",
            "Corp": "5.007172857"
        },
        {
            "Year": "1942",
            "S&P500": "19.17376295",
            "T-Bill": "0.3425",
            "UST": "2.294868237",
            "Corp": "5.179901043"
        },
        {
            "Year": "1943",
            "S&P500": "25.06131013",
            "T-Bill": "0.38",
            "UST": "2.49",
            "Corp": "8.04467006"
        },
        {
            "Year": "1944",
            "S&P500": "19.03067695",
            "T-Bill": "0.38",
            "UST": "2.577611158",
            "Corp": "6.565863588"
        },
        {
            "Year": "1945",
            "S&P500": "35.82108434",
            "T-Bill": "0.38",
            "UST": "3.804417342",
            "Corp": "6.799865478"
        },
        {
            "Year": "1946",
            "S&P500": "-8.429147465",
            "T-Bill": "0.38",
            "UST": "3.128374538",
            "Corp": "2.508032977"
        },
        {
            "Year": "1947",
            "S&P500": "5.2",
            "T-Bill": "0.600833333",
            "UST": "0.919696806",
            "Corp": "0.262120227"
        },
        {
            "Year": "1948",
            "S&P500": "5.704575163",
            "T-Bill": "1.045",
            "UST": "1.951036941",
            "Corp": "3.436959561"
        },
        {
            "Year": "1949",
            "S&P500": "18.30322368",
            "T-Bill": "1.115",
            "UST": "4.663485183",
            "Corp": "5.377301118"
        },
        {
            "Year": "1950",
            "S&P500": "30.80553901",
            "T-Bill": "1.203333333",
            "UST": "0.429595742",
            "Corp": "4.238817306"
        },
        {
            "Year": "1951",
            "S&P500": "23.67846304",
            "T-Bill": "1.5175",
            "UST": "-0.295313922",
            "Corp": "-0.190980913"
        },
        {
            "Year": "1952",
            "S&P500": "18.15098864",
            "T-Bill": "1.7225",
            "UST": "2.267996192",
            "Corp": "4.441241505"
        },
        {
            "Year": "1953",
            "S&P500": "-1.208204742",
            "T-Bill": "1.890833333",
            "UST": "4.143840259",
            "Corp": "1.620112382"
        },
        {
            "Year": "1954",
            "S&P500": "52.56332124",
            "T-Bill": "0.938333333",
            "UST": "3.289803456",
            "Corp": "6.157905182"
        },
        {
            "Year": "1955",
            "S&P500": "32.59733185",
            "T-Bill": "1.725",
            "UST": "-1.336439129",
            "Corp": "2.044690004"
        },
        {
            "Year": "1956",
            "S&P500": "7.439511873",
            "T-Bill": "2.6275",
            "UST": "-2.255773817",
            "Corp": "-2.352654198"
        },
        {
            "Year": "1957",
            "S&P500": "-10.45736019",
            "T-Bill": "3.225",
            "UST": "6.797012847",
            "Corp": "-0.71892844"
        },
        {
            "Year": "1958",
            "S&P500": "43.71995499",
            "T-Bill": "1.770833333",
            "UST": "-2.099018176",
            "Corp": "6.430092897"
        },
        {
            "Year": "1959",
            "S&P500": "12.05645716",
            "T-Bill": "3.385833333",
            "UST": "-2.646631259",
            "Corp": "1.57434309"
        },
        {
            "Year": "1960",
            "S&P500": "0.336535315",
            "T-Bill": "2.883333333",
            "UST": "11.63950369",
            "Corp": "6.663187163"
        },
        {
            "Year": "1961",
            "S&P500": "26.63771296",
            "T-Bill": "2.354166667",
            "UST": "2.060920808",
            "Corp": "5.1"
        },
        {
            "Year": "1962",
            "S&P500": "-8.811460517",
            "T-Bill": "2.773333333",
            "UST": "5.693544054",
            "Corp": "6.495327994"
        },
        {
            "Year": "1963",
            "S&P500": "22.6119271",
            "T-Bill": "3.159166667",
            "UST": "1.684162074",
            "Corp": "5.464480571"
        },
        {
            "Year": "1964",
            "S&P500": "16.41545588",
            "T-Bill": "3.546666667",
            "UST": "3.728064891",
            "Corp": "5.161739272"
        },
        {
            "Year": "1965",
            "S&P500": "12.39924248",
            "T-Bill": "3.949166667",
            "UST": "0.718855094",
            "Corp": "3.190009462"
        },
        {
            "Year": "1966",
            "S&P500": "-9.970954236",
            "T-Bill": "4.8625",
            "UST": "2.907940932",
            "Corp": "-3.445361598"
        },
        {
            "Year": "1967",
            "S&P500": "23.80296651",
            "T-Bill": "4.306666667",
            "UST": "-1.580620993",
            "Corp": "0.895226615"
        },
        {
            "Year": "1968",
            "S&P500": "10.81486265",
            "T-Bill": "5.338333333",
            "UST": "3.274619695",
            "Corp": "4.845146224"
        },
        {
            "Year": "1969",
            "S&P500": "-8.241371076",
            "T-Bill": "6.666666667",
            "UST": "-5.014049321",
            "Corp": "-2.025164251"
        },
        {
            "Year": "1970",
            "S&P500": "3.561144905",
            "T-Bill": "6.391666667",
            "UST": "16.75473718",
            "Corp": "5.649567657"
        },
        {
            "Year": "1971",
            "S&P500": "14.2211503",
            "T-Bill": "4.3325",
            "UST": "9.78689662",
            "Corp": "14.00146617"
        },
        {
            "Year": "1972",
            "S&P500": "18.75536292",
            "T-Bill": "4.0725",
            "UST": "2.81844905",
            "Corp": "11.40909358"
        },
        {
            "Year": "1973",
            "S&P500": "-14.30804744",
            "T-Bill": "7.031666667",
            "UST": "3.658664602",
            "Corp": "4.318040485"
        },
        {
            "Year": "1974",
            "S&P500": "-25.90178575",
            "T-Bill": "7.83",
            "UST": "1.988608693",
            "Corp": "-4.380719798"
        },
        {
            "Year": "1975",
            "S&P500": "36.99513711",
            "T-Bill": "5.775",
            "UST": "3.605253603",
            "Corp": "11.04996407"
        },
        {
            "Year": "1976",
            "S&P500": "23.830999",
            "T-Bill": "4.974166667",
            "UST": "15.98456074",
            "Corp": "19.75281399"
        },
        {
            "Year": "1977",
            "S&P500": "-6.979704076",
            "T-Bill": "5.269166667",
            "UST": "1.289960607",
            "Corp": "9.954662852"
        },
        {
            "Year": "1978",
            "S&P500": "6.509283912",
            "T-Bill": "7.188333333",
            "UST": "-0.777580691",
            "Corp": "3.137584977"
        },
        {
            "Year": "1979",
            "S&P500": "18.51949017",
            "T-Bill": "10.06916667",
            "UST": "0.670720312",
            "Corp": "-2.009110144"
        },
        {
            "Year": "1980",
            "S&P500": "31.73524551",
            "T-Bill": "11.43416667",
            "UST": "-2.989744252",
            "Corp": "-3.315678337"
        },
        {
            "Year": "1981",
            "S&P500": "-4.702390247",
            "T-Bill": "14.025",
            "UST": "8.199215336",
            "Corp": "8.462399481"
        },
        {
            "Year": "1982",
            "S&P500": "20.41905508",
            "T-Bill": "10.61416667",
            "UST": "32.81454949",
            "Corp": "29.05245566"
        },
        {
            "Year": "1983",
            "S&P500": "22.33715586",
            "T-Bill": "8.610833333",
            "UST": "3.200209445",
            "Corp": "16.19428962"
        },
        {
            "Year": "1984",
            "S&P500": "6.146142",
            "T-Bill": "9.5225",
            "UST": "13.73336434",
            "Corp": "15.61920733"
        },
        {
            "Year": "1985",
            "S&P500": "31.23514949",
            "T-Bill": "7.479166667",
            "UST": "25.71248821",
            "Corp": "23.86264185"
        },
        {
            "Year": "1986",
            "S&P500": "18.49457876",
            "T-Bill": "5.978333333",
            "UST": "24.28421514",
            "Corp": "21.48551531"
        },
        {
            "Year": "1987",
            "S&P500": "5.812721642",
            "T-Bill": "5.775",
            "UST": "-4.960508938",
            "Corp": "2.289846084"
        },
        {
            "Year": "1988",
            "S&P500": "16.53719281",
            "T-Bill": "6.6675",
            "UST": "8.223595843",
            "Corp": "15.11507007"
        },
        {
            "Year": "1989",
            "S&P500": "31.47518364",
            "T-Bill": "8.111666667",
            "UST": "17.69364716",
            "Corp": "15.78966653"
        },
        {
            "Year": "1990",
            "S&P500": "-3.064451613",
            "T-Bill": "7.493333333",
            "UST": "6.235375334",
            "Corp": "6.140062886"
        },
        {
            "Year": "1991",
            "S&P500": "30.23484313",
            "T-Bill": "5.375",
            "UST": "15.00451002",
            "Corp": "17.85348715"
        },
        {
            "Year": "1992",
            "S&P500": "7.493727972",
            "T-Bill": "3.431666667",
            "UST": "9.361637316",
            "Corp": "12.17225587"
        },
        {
            "Year": "1993",
            "S&P500": "9.967051479",
            "T-Bill": "2.9975",
            "UST": "14.21095759",
            "Corp": "16.43151722"
        },
        {
            "Year": "1994",
            "S&P500": "1.325920677",
            "T-Bill": "4.246666667",
            "UST": "-8.036655551",
            "Corp": "-1.319203348"
        },
        {
            "Year": "1995",
            "S&P500": "37.1951989",
            "T-Bill": "5.49",
            "UST": "23.48078011",
            "Corp": "20.15621817"
        },
        {
            "Year": "1996",
            "S&P500": "22.68096602",
            "T-Bill": "5.005833333",
            "UST": "1.428607793",
            "Corp": "4.792599419"
        },
        {
            "Year": "1997",
            "S&P500": "33.1036531",
            "T-Bill": "5.060833333",
            "UST": "9.939130273",
            "Corp": "11.83488724"
        },
        {
            "Year": "1998",
            "S&P500": "28.33795328",
            "T-Bill": "4.776666667",
            "UST": "14.92143192",
            "Corp": "7.945456133"
        },
        {
            "Year": "1999",
            "S&P500": "20.88535099",
            "T-Bill": "4.638333333",
            "UST": "-8.254214796",
            "Corp": "0.843163475"
        },
        {
            "Year": "2000",
            "S&P500": "-9.031818955",
            "T-Bill": "5.816666667",
            "UST": "16.65526713",
            "Corp": "9.329685521"
        },
        {
            "Year": "2001",
            "S&P500": "-11.84975914",
            "T-Bill": "3.388333333",
            "UST": "5.572181189",
            "Corp": "7.819150754"
        },
        {
            "Year": "2002",
            "S&P500": "-21.96604796",
            "T-Bill": "1.6025",
            "UST": "15.11640038",
            "Corp": "12.17786769"
        },
        {
            "Year": "2003",
            "S&P500": "28.35580005",
            "T-Bill": "1.010833333",
            "UST": "0.375318588",
            "Corp": "13.5320121"
        },
        {
            "Year": "2004",
            "S&P500": "10.74277594",
            "T-Bill": "1.371666667",
            "UST": "4.490683702",
            "Corp": "9.888628409"
        },
        {
            "Year": "2005",
            "S&P500": "4.834477523",
            "T-Bill": "3.146666667",
            "UST": "2.86753296",
            "Corp": "4.917537987"
        },
        {
            "Year": "2006",
            "S&P500": "15.61255798",
            "T-Bill": "4.726666667",
            "UST": "1.961001242",
            "Corp": "7.048397663"
        },
        {
            "Year": "2007",
            "S&P500": "5.484735246",
            "T-Bill": "4.353333333",
            "UST": "10.20992193",
            "Corp": "3.150386153"
        },
        {
            "Year": "2008",
            "S&P500": "-36.55234411",
            "T-Bill": "1.365",
            "UST": "20.10127993",
            "Corp": "-5.065714629"
        },
        {
            "Year": "2009",
            "S&P500": "25.93523388",
            "T-Bill": "0.15",
            "UST": "-11.11669531",
            "Corp": "23.32950249"
        },
        {
            "Year": "2010",
            "S&P500": "14.82109228",
            "T-Bill": "0.136666667",
            "UST": "8.46293388",
            "Corp": "8.347842366"
        },
        {
            "Year": "2011",
            "S&P500": "2.098374734",
            "T-Bill": "0.0525",
            "UST": "16.035335",
            "Corp": "12.5845144"
        },
        {
            "Year": "2012",
            "S&P500": "15.89058524",
            "T-Bill": "0.085833333",
            "UST": "2.971571978",
            "Corp": "10.12467788"
        },
        {
            "Year": "2013",
            "S&P500": "32.14508586",
            "T-Bill": "0.058333333",
            "UST": "-9.104568794",
            "Corp": "-1.055901207"
        },
        {
            "Year": "2014",
            "S&P500": "13.52442165",
            "T-Bill": "0.0325",
            "UST": "10.74618045",
            "Corp": "10.38490782"
        },
        {
            "Year": "2015",
            "S&P500": "1.378891641",
            "T-Bill": "0.0525",
            "UST": "1.284299671",
            "Corp": "-0.697518368"
        },
        {
            "Year": "2016",
            "S&P500": "11.77308087",
            "T-Bill": "0.3175",
            "UST": "0.69055047",
            "Corp": "10.36510582"
        },
        {
            "Year": "2017",
            "S&P500": "21.60548143",
            "T-Bill": "0.930833333",
            "UST": "2.801716271",
            "Corp": "9.723901946"
        },
        {
            "Year": "2018",
            "S&P500": "-4.226869289",
            "T-Bill": "1.939166667",
            "UST": "-0.016692386",
            "Corp": "-2.762628222"
        },
        {
            "Year": "2019",
            "S&P500": "31.21168",
            "T-Bill": "1.55",
            "UST": "9.635630742",
            "Corp": "15.32945756"
        },
        {
            "Year": "2020",
            "S&P500": "18.01391614",
            "T-Bill": "0.09",
            "UST": "11.33189765",
            "Corp": "10.41153716"
        }
    ]
    
    const spx = []
    const tbills = []
    const ust = []
    const corp = []

    for (let i=0; i < data.length; i++) {
        spx.push(data[i]['S&P500'])
        tbills.push(data[i]['T-Bill'])
        ust.push(data[i]['UST'])
        corp.push(data[i]['Corp'])
    }

    function createWeight() {
        let weight1 = Math.random()
        let weight2 = Math.random()
        // let weight3 = Math.random()
        let weight4 = Math.random()
        let sumWeight = weight1 + weight2 + weight4
        // let sumWeight = weight1 + weight2 + weight3 + weight4
        weight1 = weight1 / sumWeight
        weight2 = weight2 / sumWeight
        // weight3 = weight3 / sumWeight
        weight4 = weight4 / sumWeight
        let weight = [weight1, weight2, weight4]
        // let weight = [weight1, weight2, weight3, weight4]
        return weight
    }

    let weights = []

    for (let i=0; i < 1; i++) {
        weights.push(createWeight())
    }

    let returns = []
    let avgReturns = []
    let stdev = []

    for (let i=0; i < weights.length; i++) {
        let w1 = weights[i][0]
        let w2 = weights[i][1]
        let w3 = weights[i][2]
        // let w4 = weights[i][3]
        let rs = []

        for (let j=0; j < spx.length; j++) {
            let r = w1 * spx[j] + w2 * tbills[j] + w3 * corp[j]
            // let r = w1 * spx[j] + w2 * tbills[j] + w3 * ust[j] + w4 * corp[j]
            rs.push(r)
        }
        returns.push(rs)
    }

    function getMean (array) {
        const n = array.length
        return array.reduce((a, b) => a + b) / n
    }

    function getStandardDeviation (array) {
        const n = array.length
        const mean = array.reduce((a, b) => a + b) / n
        return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
    }

    for (let i=0; i < returns.length; i++) {
        avgReturns.push(getMean(returns[i]))
        stdev.push(getStandardDeviation(returns[i]))
    }

    function getPortolio () {
        let portfolioReturns = []
        for (let i=0; i < spx.length; i++) {
            let toggleReturn = toggleSPX * spx[i] + toggleTbills * tbills[i] + toggleUST * ust[i] + toggleCorp * corp[i]
            portfolioReturns.push(toggleReturn/100)
        }
        setPortfolioY(getMean(portfolioReturns))
        setPortfolioX(getStandardDeviation(portfolioReturns))
        setSumToggles(parseInt(toggleSPX) + parseInt(toggleTbills) + parseInt(toggleUST) + parseInt(toggleCorp))
    }

    const testy = [
        7.556935700775586,
        5.742482601404076,
        7.41397307550217,
        9.156177771930391,
        5.57934036106351,
        6.808128786053006,
        7.881381516750744,
        6.049164587282828,
        10.397617401996142,
        6.923849870649656,
        5.951477928593565,
        9.484991522113889,
        9.306147421569008,
        10.172529867766766,
        7.489566060487748,
        7.715790062102048,
        8.810650044609577,
        7.167755611697707,
        8.498499172189037,
        8.223272435737725,
        7.089527185846094,
        5.7256674953631395,
        6.811395953251882,
        7.834801518900278,
        6.89450470927172,
        6.814393194536417,
        6.70182136286156,
        6.73928453826839,
        7.076525679491303,
        8.302591040479196,
        8.442088521407088,
        7.689343321338486,
        9.199220926522505,
        7.925223663433352,
        9.235205592829347,
        8.349116830462489,
        8.470235269542261,
        8.459510554803597,
        6.391002853326418,
        8.113874488102283,
        8.598930125943593,
        7.701882679837102,
        6.492354244458491,
        7.544709383390434,
        7.884976498586662,
        8.09217984223529,
        8.086047535517915,
        8.76961008973386,
        5.087377769168613,
        7.824472967444018,
        6.031015740491058,
        6.433416531082596,
        7.239512195086643,
        6.014040768621205,
        10.328985555133606,
        5.595123859626123,
        9.16450489078533,
        6.924392245880935,
        7.990175964665283,
        10.097138037620539,
        7.109539875905475,
        7.133960305764149,
        5.333212344490985,
        9.91366760812001,
        6.5069995780266865,
        7.6332478528572985,
        8.534851176556963,
        6.960602366816651,
        8.887874042907825,
        8.557849157721337,
        7.580877868556953,
        7.123965434993147,
        8.15241915864603,
        10.5675579054354,
        8.225504643488021,
        10.08313219900974,
        8.779516561094509,
        8.500912382524472,
        7.073637230307708,
        5.58636921125258,
        8.86102119698425,
        8.406036097774125,
        7.641112172752888,
        5.091290226316503,
        7.1140117091649335,
        5.402825824920573,
        7.6660099659920915,
        4.940440938784363,
        6.826443867863402,
        7.885687438755655,
        9.170309281810736,
        7.228962579301083,
        7.302021483366671,
        5.310925902166619,
        8.91250691719554,
        7.7185585560717875,
        8.508346958168646,
        8.121449109243251,
        7.642224469373966,
        7.7051579539405575,
        7.570331422787846,
        4.345902702301653,
        7.1627933193983155,
        7.752420103918498,
        6.033816317333724,
        7.058352417477021,
        8.62912192554961,
        9.488060108150822,
        7.941980180852719,
        6.429698405105341,
        7.571855212638553,
        8.79624597757191,
        7.343798582461713,
        7.279911823228618,
        5.571920448671355,
        7.8036711386953135,
        8.326873041042331,
        7.997045519216772,
        8.05133152742248,
        7.80533414290083,
        8.43969143329375,
        7.10763520852744,
        9.10389485668706,
        8.068979344330385,
        5.798515749098504,
        9.466670790621128,
        7.772277039207226,
        7.131209442773218,
        5.950802250327457,
        7.399483066833474,
        7.751355601495159,
        5.760776652207217,
        6.786724064975358,
        7.365251657268905,
        9.387728262442154,
        7.589761397022207,
        8.559877248485728,
        9.11403905458745,
        9.360830971733598,
        9.461777066964446,
        6.42176339054375,
        9.419964675015008,
        7.396702012059398,
        7.00772803081866,
        8.506692608467514,
        9.560318907353832,
        7.101040261053914,
        6.917257129220301,
        9.21590262579928,
        7.331695400325232,
        6.7707863772433585,
        8.108078379919128,
        7.172344288842887,
        5.707079102653494,
        6.028019664687797,
        8.622548437787728,
        8.004936401729045,
        7.928171999031767,
        8.374903020100291,
        7.533284303945447,
        6.002202489913208,
        5.544018131628526,
        7.1588884491834595,
        7.965857656941397,
        9.079802741774108,
        6.576443305280582,
        7.530822791852405,
        8.038532028270687,
        8.781807562949519,
        9.62840031609244,
        7.594994953308431,
        7.202233180764931,
        8.002381487174539,
        8.570333431483006,
        6.175742293582405,
        7.954419487196278,
        9.163925562237138,
        6.915602938596832,
        8.938846005383155,
        6.487449133866336,
        7.047031029028201,
        8.615922455286231,
        7.963750019205756,
        9.891531100337717,
        6.662177356954895,
        6.968531281403595,
        7.657306024321172,
        5.14740111863029,
        7.02462542520203,
        5.239686074680725,
        6.39198529710768,
        7.479204457312212,
        5.774719433556906,
        6.471838302463964,
        9.255516998741417,
        6.4247414557796425,
        7.5551490616641,
        8.216437034376916,
        6.9815443281222835,
        5.747649428301012,
        7.275968710154783,
        8.45377107135892,
        8.28455452595975,
        5.850431826540066,
        5.967399866353723,
        6.775746826453751,
        9.54473573394219,
        6.992521822505656,
        7.765105836201553,
        8.578243785188004,
        5.681447494169174,
        8.23232094910578,
        10.021672259331826,
        5.855745156106706,
        6.117280550916731,
        7.4535932428808165,
        8.039797055987188,
        5.80267701375403,
        8.424004013449354,
        8.903084402701303,
        6.6405377151861265,
        4.897595776942583,
        6.495616036627355,
        9.288650259754885,
        7.018505407506136,
        7.503232765708809,
        8.101500251585104,
        6.0319454267355495,
        7.235057702506775,
        7.312219532505866,
        10.534084267127245,
        8.930739199846565,
        7.589487055198743,
        9.663842048874338,
        7.715538396909589,
        8.477487182895677,
        9.466952090465421,
        4.6081654217080255,
        4.6450150845986045,
        8.664169122351241,
        7.770319515389132,
        8.633776367383991,
        7.039399353107066,
        8.340306273315148,
        8.215708325301698,
        8.256467101425807,
        7.241739864865033,
        6.041360755230406,
        6.435170277875387,
        7.372371428691313,
        5.059068210110051,
        9.00604660288553,
        8.725620987831503,
        7.607438787688179,
        9.770567172538208,
        5.587556359705205,
        6.482279790183297,
        5.064236730146567,
        7.251107770440135,
        5.893357633227326,
        8.24054872267822,
        7.168466604872915,
        4.2454743993974695,
        8.313271509789582,
        5.30778874204,
        8.25483762755912,
        8.836805082057989,
        6.807559033371609,
        8.291181972011868,
        10.152029235793574,
        6.9119541022513244,
        6.910450725114482,
        7.275224172661929,
        7.5934872622070415,
        7.584054966894364,
        8.77134243745593,
        8.549783209508846,
        10.658841740240767,
        8.6410513828218,
        7.533267237642655,
        9.136231028108462,
        6.701906373390324,
        9.005505953324505,
        6.8341648580298715,
        4.730122950063935,
        6.4485077507980195,
        9.55240915809237,
        6.7183912398079855,
        5.667648241508899,
        9.707897276938077,
        8.881903946943137,
        9.759919193888662,
        5.754734869264881,
        8.518401561486593,
        10.792787058585146,
        6.705669140474857,
        6.867960296111295,
        4.29409861111142,
        7.555358251009204,
        6.394817758385127,
        6.617794730625942,
        5.598706064700003,
        7.560822269066247,
        6.9558810599375756,
        6.432468576474088,
        7.213503173757385,
        7.11982661732824,
        8.111458941525635,
        7.34232893679169,
        5.336553799911219,
        8.357557161365184,
        6.389724381514604,
        5.673148831230373,
        6.828521453816584,
        6.960577562756261,
        9.621784196174987,
        5.416185512414014,
        8.469853265465234,
        8.10991777140635,
        7.252660671909805,
        5.0626919861194075,
        5.876615713369197,
        7.366767506168825,
        8.840570262579039,
        9.4390011357988,
        6.5035100651315,
        7.096909656976588,
        6.232142319182949,
        6.483234243064787,
        7.129754660704527,
        7.538580243883959,
        7.653113609868696,
        7.034000975009642,
        7.333689398872716,
        5.813710582386673,
        7.427375140788052,
        8.374173833041096,
        5.378990092287145,
        6.247309915426909,
        5.468273431574368,
        5.2547895113840575,
        7.675865227570362,
        8.099850022343901,
        8.742348915836239,
        6.704550181982471,
        7.174755042411295,
        7.891008038516838,
        6.939509171922403,
        7.061494209508786,
        5.505608470979109,
        4.977720777502974,
        6.970154832580605,
        5.150533450909044,
        7.350415450325705,
        7.088047862761576,
        7.310385475249084,
        7.336424582040078,
        7.282120885336429,
        7.619873432478252,
        9.183210475913894,
        5.866813744846737,
        8.6370893084253,
        5.071159072155645,
        5.772046222937748,
        6.678093511445594,
        7.503523874899738,
        7.632922955322022,
        8.169364259968727,
        6.471674937177191,
        5.809134180868689,
        7.253248113503433,
        7.084211894245939,
        8.498624009540697,
        6.714150216725277,
        6.972998755531125,
        7.549805008752413,
        8.652330453971198,
        9.731704298218514,
        7.611210545947428,
        6.557785074311878,
        5.410509037964953,
        9.80232692808952,
        7.660643059377542,
        7.744009344689282,
        8.396864777785915,
        5.358420119178947,
        8.473731904917452,
        6.762066456282212,
        7.619329192700606,
        7.034536813883626,
        7.923633387335579,
        5.482701724937774,
        9.45760121907275,
        8.053918161284207,
        7.975988618817234,
        7.243165624062847,
        6.493746476461578,
        9.12472377398524,
        7.667737898428654,
        6.665321679542954,
        7.07322275794865,
        6.931152893114427,
        8.047962968337982,
        6.208676036958045,
        7.703570680466879,
        7.682586277415434,
        6.810770870498225,
        9.897806717290553,
        6.631792685697039,
        6.852526538133172,
        6.888730681726925,
        5.811051818546463,
        7.673505963366741,
        7.840364976203157,
        5.947918282934961,
        6.548631856639925,
        8.025929228072354,
        9.253919698302742,
        8.64828826272491,
        6.365662281638139,
        6.7829789372991165,
        6.9581248547494505,
        8.086260270251346,
        7.225148398036594,
        7.878983242876618,
        6.8171888043400655,
        7.166002178214044,
        7.612204134594354,
        9.876765411781545,
        7.468128525209942,
        7.450169944874347,
        6.819223135960114,
        9.146417785382527,
        8.204794960633789,
        7.726185460472097,
        7.931753516100717,
        6.638699164722425,
        8.655886650019267,
        5.426676286648069,
        5.342059852617327,
        5.111074988638883,
        8.82885661330643,
        8.57592437913867,
        7.30613440904449,
        6.68580619054226,
        7.664391576807898,
        7.531139519615084,
        5.0548134442871975,
        6.725582363868749,
        9.069071421251477,
        7.771383831613992,
        5.963791719526862,
        9.180438499055276,
        7.172949044179052,
        7.90183261185073,
        5.336549073401189,
        7.550924030086369,
        5.331665008697236,
        8.540389046732674,
        7.615913824444183,
        8.371612539123259,
        6.815045426006828,
        7.264745189623718,
        8.350526864458784,
        9.58698565684497,
        5.661790132482361,
        7.625238583463562,
        8.108819198495668,
        6.5263920570159675,
        8.005617054071758,
        7.34521417267312,
        4.803657721221499,
        6.042452808155581,
        5.794724938106931,
        10.450055887054768,
        7.7297938241503505,
        6.540722228393873,
        8.866986548537337,
        6.490447431092484,
        8.153859957134939,
        9.448544293112528,
        8.479861778172564,
        7.773657127506418,
        8.204427995661536,
        8.173977832175419,
        5.3464269898053365,
        5.346551529330544,
        6.995942787331692,
        5.6439921384383975,
        7.491393414217318,
        8.284659164983085,
        6.759451450601393,
        8.193026293870643,
        6.2009408509121355,
        8.575215054773462,
        6.551801387904164,
        6.888093191546958,
        8.613946754036327,
        6.728063005170078,
        6.58240093860795,
        8.227982094591928,
        6.554210777418003,
        6.24764088203859,
        5.282679450260605,
        6.0945995057420825,
        8.504762856094152,
        8.877757645362289,
        8.910077583552937,
        9.440885065596987,
        7.342471527715776,
        6.194120066106388,
        7.814585276278456,
        6.983264345855375,
        6.898659535087007,
        6.845989876174551,
        8.44190004139385,
        4.934576584219272,
        5.387466966679258,
        7.564255860657681,
        7.468807027687496,
        5.477682100118555,
        7.3311660583542295,
        7.67860712044897,
        7.167893301068795,
        8.183167332639497,
        7.889228376357412,
        7.217321997709479,
        8.60769566276085,
        8.951166729318471,
        6.179490210657224,
        8.13691818500396,
        7.01967875718731,
        8.136543474134829,
        7.919080434376674,
        7.644940627962721,
        7.490954534400888,
        8.486709520872667,
        10.672969663415785,
        9.122067081386453,
        6.82141811940453,
        9.085925484276197,
        6.13238237471521,
        7.911420945532843,
        6.400685053973851,
        7.568667085813642,
        5.443835506138519,
        6.812003459478514,
        8.388337596106796,
        7.85052536060764,
        6.114083024324565,
        6.522001175417157,
        6.444717493757899,
        6.727067202059901,
        9.72246171290686,
        7.578013561236057,
        7.222233682232455,
        6.697180678468658,
        5.709194669734656,
        9.335754350739524,
        8.38909896800163,
        6.61355651520696,
        8.378421047981488,
        4.609977673902753,
        6.2098229597204995,
        8.27654885164577,
        6.523888488112168,
        9.824014511731379,
        6.181758087417851,
        7.899020626783977,
        7.702404143867553,
        8.640999095982613,
        8.136483763522945,
        5.818836167559711,
        8.032413305471994,
        6.990905991723265,
        6.971073568326815,
        8.701625900581378,
        8.237847271544204,
        9.380043090779974,
        5.478132617766353,
        8.700814517807949,
        6.118324453147297,
        6.006094770388887,
        5.410974919197384,
        7.434719858261637,
        10.653994875662468,
        8.448372114155298,
        6.371723989485233,
        4.6506248488314395,
        9.36388951527996,
        7.289789288840824,
        9.473054407589743,
        6.526672376796857,
        9.769656898875251,
        6.3185707386008385,
        7.955720868163899,
        9.264208082594322,
        5.199601050797566,
        8.299604555080526,
        8.224743816816002,
        8.22873479298153,
        7.998700719577355,
        8.203004520213698,
        7.648167826356196,
        10.710259720832124,
        7.621493984274297,
        9.354832129165608,
        10.625838743361278,
        6.225757518287512,
        6.954101250027649,
        7.9578466377656305,
        5.524233510877292,
        8.495514792242812,
        5.75393472896832,
        8.079184818954031,
        6.386180788098048,
        5.3923899585106065,
        7.6247297165040475,
        6.129428653772202,
        7.5974225293983775,
        9.150437767537408,
        9.789596300914324,
        10.903113191575823,
        7.706600100765909,
        9.09200583432873,
        6.115960921540871,
        7.8290159358231675,
        7.755573244529504,
        6.671755498767847,
        7.298368223392869,
        6.1466943150468305,
        8.6867888581761,
        8.942844020200303,
        6.769772281235531,
        7.369533489389003,
        7.889934010542736,
        9.91751327705022,
        7.613102232041807,
        6.912147263976496,
        6.929887607349938,
        4.135656353854122,
        7.187324330726054,
        9.053077821044496,
        7.921487778996987,
        5.813880569512642,
        6.589602051370824,
        8.892725449859412,
        6.375151447019419,
        3.9525093397787483,
        8.574018010956124,
        7.545505714349083,
        7.759875466697956,
        6.392917813276033,
        7.597916811544334,
        7.041244344870325,
        6.66103821874025,
        9.160648389166834,
        9.346717213987393,
        7.266261186247794,
        8.697060096177742,
        8.204230934096556,
        5.413311842672492,
        8.415212622529129,
        4.954804144426745,
        8.34041556132372,
        7.335121874663926,
        6.7548120069087085,
        5.7586892911155285,
        7.860533464654486,
        6.421550164468752,
        6.045952304900006,
        7.093090113491907,
        8.906054645016605,
        5.64362749325143,
        7.835546994580158,
        8.453457657611049,
        7.49005051601691,
        5.849424833910007,
        9.86114322073087,
        5.17179249176535,
        4.995754982053051,
        7.470418545320447,
        7.325696660428971,
        9.823588294578876,
        6.252509343137307,
        7.349866692656658,
        6.946046285328679,
        6.106721120944776,
        8.879014833293384,
        8.876973395190912,
        6.559872936756687,
        8.133801929365536,
        6.825425184758394,
        6.857030447660527,
        6.872466146548719,
        8.911572782739041,
        4.742717850778583,
        7.694321024039244,
        7.764377228941388,
        6.6725542754656475,
        4.932406508033609,
        8.379305341114843,
        7.354820205706959,
        8.087960436606178,
        5.121092354714847,
        6.934791135536316,
        6.043795379447838,
        7.2903630730434115,
        6.032122814541289,
        7.527770777298529,
        9.559721760056988,
        7.6901878177179075,
        5.403427989697627,
        7.704015370169763,
        5.785185231325801,
        5.999214956933419,
        7.548185445487872,
        5.442406828315944,
        7.008879209502818,
        7.149428666556861,
        9.071811830866542,
        6.8241165697161605,
        8.059906831176203,
        7.623997022937247,
        6.336004041769241,
        8.773162967939472,
        7.700541088031024,
        7.026846959417466,
        8.445312580514525,
        6.44869700013784,
        10.009785345350938,
        7.367522184679274,
        7.506398268724934,
        6.043236747357459,
        8.204775434701029,
        5.954623521619631,
        10.036872902654132,
        5.908532484604898,
        6.6224953824653126,
        6.367759716518656,
        7.505507179444432,
        6.044365675282357,
        8.999121088293386,
        8.87186550471093,
        6.376776454507999,
        5.159377023486393,
        9.983227750456086,
        6.316750043707963,
        6.492474701220895,
        9.077695258902864,
        7.881863423710072,
        8.967672657660572,
        9.514292489803362,
        6.606187199492426,
        9.01636097765073,
        6.346438321764615,
        6.846403513901516,
        8.147180521800749,
        6.462216046145119,
        8.607719376406079,
        6.68955955092303,
        8.634065002102087,
        4.777989564135435,
        7.370860133400394,
        9.791630636138784,
        8.205846508877057,
        7.971871143971254,
        5.352665157669799,
        3.8049145448808077,
        7.804153751676678,
        6.469040307074643,
        5.454691702745527,
        9.578786144504905,
        7.542858375479442,
        7.089797439315303,
        9.088441534650823,
        6.517547859777142,
        9.36773100655699,
        6.361978626650119,
        7.094773889727334,
        10.122728850512903,
        8.491176806390152,
        8.627079727149853,
        6.054972602859283,
        9.274445036654775,
        9.41019861680424,
        7.3105654057312925,
        7.569263054468609,
        6.562292654182319,
        7.6499083911391965,
        7.739994773288279,
        10.782924665276912,
        5.60626927657148,
        8.104227918131192,
        8.291001085547979,
        7.94261309605062,
        9.46421699132003,
        7.086992093570896,
        8.698758409559733,
        7.709669297009084,
        7.51999474215594,
        7.323036838992288,
        8.133421911330274,
        5.772671266398246,
        6.35351541739867,
        7.7153904192619915,
        6.317894678525562,
        7.6839243385601845,
        7.075170321090305,
        6.7787712704901555,
        6.8462122871860975,
        7.069899903471179,
        7.474075486188161,
        8.169393393778412,
        8.701046600412196,
        8.093076472930584,
        7.628316039452246,
        6.0038182093437245,
        9.648070333674772,
        8.0006051954979,
        8.41339236215958,
        8.32324178458251,
        8.462086963722431,
        7.272970973663035,
        6.906921835536749,
        6.323624507974828,
        6.962115574557826,
        9.007316151315353,
        8.033414934228619,
        7.542127730967925,
        5.277955538794685,
        9.276439245490637,
        8.627864823688338,
        7.469632474745477,
        8.987185811252733,
        6.336176686934072,
        8.777110832714587,
        7.083069043921217,
        8.195433426818267,
        7.220126643131373,
        5.561513648362191,
        7.663696941967927,
        7.430686490112947,
        7.884194572498725,
        7.758218808597819,
        6.476934745551138,
        5.802942622016791,
        7.353451113724817,
        7.89946708070182,
        5.903256424792888,
        8.546934313954687,
        8.792102207966302,
        8.178957296124882,
        8.957821317418889,
        5.326720322494194,
        7.233802342761465,
        6.896435000217133,
        8.034495139681011,
        8.875510766969192,
        6.909173648791748,
        5.461915487199536,
        8.716255839691094,
        6.120380342262544,
        8.022897056261398,
        8.156600626693242,
        7.736204712764388,
        7.228410943793259,
        7.972494562496322,
        9.490925125864527,
        5.841671525807366,
        10.65211330400407,
        8.88669927681944,
        9.28689406924026,
        7.22798244353739,
        7.357606046711997,
        6.917542537924483,
        6.903699565540703,
        6.397271853927512,
        6.1836790993831,
        6.776012355092245,
        7.670092897041291,
        7.309756655812495,
        6.658677393323582,
        9.57300284560366,
        9.956632107589263,
        7.118589291880696,
        8.777078524812477,
        6.603970695416252,
        8.770199458923111,
        7.101522827750912,
        9.412718262043402,
        8.432258546540137,
        8.59739313230838,
        6.722899707664604,
        6.648554326691767,
        7.670895045573535,
        7.829823865464858,
        7.419852385428188,
        7.536488060385962,
        8.110419482277603,
        9.218903202463704,
        6.845483960786975,
        6.093885050067333,
        9.04330156794932,
        7.671599650027871,
        7.32017239294173,
        9.205244449758133,
        9.380356641510867,
        7.3162346365325295,
        7.3009041469872,
        5.937560246975216,
        4.610719907925474,
        8.83069867000414,
        8.321422404618122,
        7.14153644536145,
        9.207533154110642,
        6.199282968049075,
        7.855012045886725,
        6.980472885867357,
        7.6982460874391085,
        7.654432206126956,
        6.520652796021918,
        8.85633280980463,
        8.03673783062297,
        5.912480184601967,
        7.716109002555521,
        7.541127161780472,
        6.325679979133364,
        7.242163143192616,
        4.6736038526685055,
        7.159364640769183,
        7.062361952659067,
        6.869792010070029,
        4.893037562028936,
        6.927274914766495,
        7.540516438780727,
        4.846124797346239,
        7.843947538300429,
        8.266672007340706,
        7.344300729359068,
        5.241590205429422,
        8.297621189726256,
        7.9924321047024645,
        7.037481036184133,
        7.4652317578374445,
        9.43030713309802,
        8.347038585820922,
        6.635448249214569,
        6.291859003090664,
        5.5391316210762245,
        8.086661813516562,
        9.983745351037145,
        7.566886562659322,
        5.631864799777616,
        7.189480830451387,
        8.12108661673501,
        5.943635192617281,
        9.681894321607555,
        9.594307642567912,
        6.861380040866373,
        8.487583504410802,
        8.789328385075471,
        9.697050592179982,
        7.331005949424148,
        6.1938839518934845,
        5.074098136033129,
        7.815138549998975,
        5.675385976166807,
        5.017435406962002,
        3.975784847698136,
        7.498414867131669,
        7.005976953152245,
        5.931490167166523,
        8.16833075680526,
        7.507995960540598,
        5.546345443514289,
        4.784432988300442,
        6.734300567815236,
        8.898512789415916,
        7.703951242754711,
        9.054526373874324,
        7.009542083939111,
        7.613959254134138,
        8.401773251301403,
        9.082206366742232,
        6.850445182965319,
        7.326972735428224,
        7.6065717883756045,
        6.019650854248855,
        8.053073696180464,
        8.632546158597792,
        6.5002026984566275,
        5.318570853879514,
        6.211437101267554,
        6.841482707346124,
        7.785910508507566,
        8.215339888922392,
        5.7864145743697915,
        5.622580910806882,
        5.503407513223436,
    ]
    
    
    const testx = [
        8.274994169515198,
        5.052208762272998,
        8.161807634364418,
        12.123469858088267,
        4.378314679261655,
        6.254998342060966,
        8.867611145970937,
        5.005508981070906,
        16.459358003525953,
        7.484679038343524,
        4.81249898725309,
        11.96105311406085,
        11.615240911233284,
        15.307502027663084,
        7.589205901230139,
        7.6613584099949525,
        12.073845466055902,
        7.192919599397742,
        10.20352852871629,
        9.041231282084933,
        6.684525999205406,
        4.472662749737716,
        6.730604279419615,
        8.571517235527487,
        6.798421308044321,
        6.446157960641735,
        6.199889394610937,
        6.122560692269528,
        8.634138372395716,
        8.681917842165282,
        10.377057148652483,
        7.5949133659881785,
        12.37791819187381,
        8.9967660124422,
        12.809915366477943,
        8.939382162636141,
        9.827349789530372,
        9.543819520092436,
        5.469438081431799,
        9.559147365958623,
        9.904238982382925,
        8.48519571951077,
        5.646639272940718,
        8.310672483982032,
        9.023058399477963,
        8.801238305012106,
        9.513546910141672,
        11.164503397535375,
        4.138617756408483,
        10.122863276155842,
        4.923444192040282,
        6.517741906843233,
        7.2959662269626655,
        4.891629039013653,
        14.949888246874085,
        4.7648175691900425,
        11.28508227196848,
        6.858859409380169,
        9.392176439193921,
        15.53074202127957,
        7.135425679231692,
        6.92793769838329,
        4.5741688899829915,
        13.617958427791196,
        5.7453758385580525,
        8.434609124107325,
        9.40466186355029,
        6.376727876612623,
        10.944139153019849,
        10.343369976446098,
        9.386339111802933,
        6.7931898959420005,
        10.623635878186414,
        15.72770830552264,
        9.641105434884823,
        15.256051211186435,
        10.352936240550175,
        10.245969858412307,
        6.729410763703987,
        4.443913124656377,
        10.910169784283758,
        11.4524090937839,
        7.646201729560411,
        4.274698639871209,
        7.21030435546347,
        4.041383030448519,
        8.308645424896667,
        3.9760662345564635,
        6.730430718731063,
        10.054003644190875,
        13.39723734716815,
        7.430362970465885,
        8.43457943177876,
        4.253472036806629,
        12.735414555608626,
        8.745461857839485,
        11.011838826571172,
        10.06045937985555,
        8.009974145277477,
        8.810647482930023,
        8.078431967097034,
        3.023333512527839,
        7.987740589266101,
        8.675856675490543,
        5.041403593168797,
        6.909857283086059,
        9.86319695808248,
        12.335731264252546,
        9.011224241797075,
        7.269576748568842,
        8.909116754957553,
        11.332583069190852,
        7.523625979802258,
        8.229057165537364,
        5.069673851632537,
        8.75818569912597,
        10.853893146177025,
        9.316032901736863,
        10.502384854362786,
        8.634011440178174,
        9.493403620850463,
        7.4219324573126375,
        11.272637121634308,
        9.378103454752226,
        4.5786631173055365,
        12.03308558526577,
        8.71613899457276,
        7.747986284875387,
        4.887181127804092,
        7.131815761342358,
        8.193744142985542,
        4.839808344583602,
        7.022211837237849,
        7.839475151315734,
        11.708554567737119,
        8.492903647099325,
        10.434109449857532,
        12.363425530048566,
        12.038363608230446,
        13.35289654987989,
        5.683123722507975,
        12.037267118802196,
        7.486137339568204,
        6.646053280237091,
        11.007135757164617,
        12.9851150685095,
        6.989525817635471,
        6.52569835763932,
        11.99030399384636,
        7.923269157243352,
        8.013527228280362,
        9.344307607859411,
        8.684267323668239,
        4.638967119314799,
        5.181658872431288,
        10.541615892175564,
        10.731136165330842,
        9.884692204102546,
        9.526245592242313,
        8.596755256863016,
        5.604976508558021,
        4.910601435132163,
        7.443854144139581,
        9.191494470966646,
        11.905101044854646,
        5.855451476917092,
        9.65338388682609,
        9.761957662928584,
        10.930107743483802,
        12.350297983390606,
        8.343748349158673,
        7.1003636336244815,
        8.278858705100491,
        11.056550429004991,
        5.293538785803286,
        8.898413990321108,
        11.720612745017103,
        6.792960457603605,
        11.07429139548474,
        5.7189242812182846,
        6.810297241860227,
        10.013923312727258,
        8.54607558339736,
        13.710418710877741,
        6.492731497644265,
        7.229658669695743,
        9.697787190696863,
        3.8773157560271816,
        6.52570652315088,
        3.889823507523496,
        5.749751785388808,
        8.772298518216598,
        4.565124942269833,
        5.987838037182318,
        11.189228177361636,
        5.593145396936712,
        8.887674182714306,
        8.908796852788964,
        7.101245416425986,
        5.889562677267708,
        8.865481694346743,
        10.07872328478552,
        9.234130757138495,
        4.756152434404336,
        4.844218124209405,
        7.894099227058698,
        12.887191824554103,
        6.724368133445447,
        8.733315712305561,
        9.358472378791546,
        4.464968039813148,
        9.27339825414812,
        13.984442209168954,
        5.03580471610689,
        5.658946537577108,
        8.973449755136444,
        8.958505369184671,
        4.6951896110661515,
        9.92875166870923,
        10.603931234458665,
        6.11455902319316,
        3.4565102835375785,
        6.102505485284819,
        11.285263215403056,
        6.493940496338883,
        7.790844277243596,
        8.303643410031343,
        6.103281812425358,
        8.218836444001038,
        7.364366193748337,
        15.88981674628069,
        12.272111555400814,
        8.705151416070493,
        12.973041953886305,
        8.573554662466789,
        11.374565382959492,
        13.896232568678364,
        3.432314647426267,
        3.544527622815708,
        11.06503005621563,
        7.790674453099874,
        10.255193933665776,
        7.006203736254605,
        9.999672861134162,
        11.139455647626619,
        10.36463693330502,
        7.139190746212553,
        6.258314773640559,
        5.611119313819123,
        7.394723992567301,
        4.200960563991189,
        12.129551313862715,
        10.342366410497664,
        8.617371364113879,
        13.286286480948158,
        5.465791015030664,
        7.176715194000638,
        3.8410588704761865,
        7.596070644275784,
        4.970460809376937,
        8.800884181846865,
        8.085364942734849,
        3.0019743676032604,
        8.955190984587146,
        4.9530380319291405,
        11.453658714507023,
        11.027925532844032,
        7.068241780543081,
        9.464421767013468,
        14.096816397930734,
        6.799578493792993,
        7.139547540793212,
        7.492263803495333,
        8.505289616644212,
        9.050403852221551,
        11.364079778188737,
        10.87539520158531,
        16.520457452865525,
        10.383952421325516,
        7.3360086844961545,
        12.6531175304927,
        5.96381344030143,
        11.765378740161287,
        7.034587837428031,
        3.300690460689455,
        5.554876517412731,
        12.060423741464405,
        6.443356381796732,
        4.393967940668289,
        12.923826686446398,
        12.120747383268922,
        12.905455124534903,
        4.6308212096709225,
        9.508647524581605,
        16.803548457570447,
        5.9747798418619915,
        6.426286788012287,
        2.9873771237438387,
        9.411422572876553,
        5.696484945292347,
        6.320222558883307,
        4.370487978528854,
        7.874858423472281,
        6.398284917859521,
        5.555019752472896,
        9.046863762768627,
        7.457877811404537,
        8.850723853156765,
        7.1284428921018295,
        4.12213254823507,
        9.89841722495627,
        5.462229096945624,
        4.427074438912781,
        7.240259312119749,
        7.737685690710369,
        13.176506875539914,
        4.166909679033681,
        9.924413303806595,
        8.38336775093887,
        7.483642126972504,
        3.692621873478703,
        4.688963908946455,
        7.736823989990701,
        10.10075685315881,
        11.814529161820445,
        5.659243947906161,
        7.216776853573391,
        5.478110857102048,
        5.657872273534977,
        8.063559187014441,
        8.6425784529428,
        8.433281871919544,
        6.968079017940509,
        7.543722723027563,
        4.739044866239223,
        7.878256115732323,
        10.761445093351288,
        4.0678967814283755,
        6.014887829446384,
        4.228053923934393,
        3.9087280197740117,
        8.726581904429526,
        10.785563230457877,
        9.938887186727905,
        6.185953570217275,
        7.174439234784164,
        8.529284429506282,
        6.647123899708605,
        7.73377416577223,
        5.360878229385306,
        3.6306810456439247,
        7.563770431233631,
        3.732625287206117,
        8.305569025844013,
        7.218795811050474,
        8.633901265547628,
        7.264572762938536,
        7.213445235722682,
        8.39231313421098,
        11.076929660908473,
        4.756666918219259,
        11.93770351341855,
        3.8162870730067784,
        4.879711223681189,
        5.921915479104053,
        8.004929780334841,
        7.7357090016577725,
        9.968215305411963,
        5.622282471946802,
        4.705455553637507,
        7.192250450244942,
        7.500939402311115,
        11.787979147103739,
        5.979418151258268,
        6.529789651663389,
        8.171229228011462,
        11.067395043115289,
        13.724122148860634,
        8.21159150222725,
        5.757418514071647,
        4.449257716168822,
        14.784484716563117,
        8.999529920809522,
        9.98936060792005,
        10.326436760444826,
        5.016312966883125,
        9.743354555437307,
        6.445981797421443,
        7.9188043908756205,
        6.839449776435777,
        9.270578738446346,
        4.315606790262772,
        13.97361952191633,
        10.136193769765699,
        8.280852757890534,
        8.010287108233165,
        5.951028673246391,
        11.134361295886958,
        8.447367326977005,
        6.377044007359627,
        7.347549622272135,
        7.226232146521592,
        10.992669924381161,
        5.6091798597528,
        8.412055227703275,
        8.362838354956123,
        6.407089266584963,
        14.117293904970035,
        6.021402178942629,
        8.145855778878051,
        6.262933761529285,
        4.614399343578461,
        8.110483365749003,
        9.179001867840782,
        4.794758299906513,
        6.72554831471899,
        10.52155011454687,
        13.218574730382223,
        10.24161631925855,
        5.80129565865481,
        7.799806405906873,
        7.634527496716421,
        10.672979840915,
        7.661727202988989,
        8.439093218370186,
        7.539593894584872,
        7.16590209541651,
        9.059322024785738,
        13.442078097682005,
        7.55800744940558,
        9.02574760341636,
        7.882927435547102,
        11.38225908680769,
        9.629025784299838,
        8.580636317534417,
        8.230478034999882,
        7.005927259774833,
        11.868968315639735,
        4.167004995634552,
        4.075185623515558,
        3.7639660654418843,
        10.596172810485589,
        9.395387000190567,
        7.89544787236308,
        6.730603245210064,
        7.801114971979725,
        7.672705761785313,
        3.701541792384154,
        6.151403666352773,
        12.734582563647242,
        8.103812939305774,
        4.81765004147896,
        12.710586284403988,
        8.892928822853122,
        8.529218952100567,
        4.158691449051205,
        8.346921786732825,
        4.168809962552129,
        9.662338427005878,
        7.4703900019914755,
        9.847479117214124,
        6.185244001705721,
        7.172134417226318,
        9.78800614043513,
        12.68752807755691,
        4.387088040346849,
        8.856541348322414,
        8.989088914202654,
        5.8235187031553695,
        9.097653840151724,
        7.982088399933761,
        3.42949953997438,
        4.9802944997606655,
        4.570418015929609,
        16.24703293425856,
        8.488626642339119,
        5.700638714162104,
        10.410990284686934,
        5.6350031713250734,
        8.850993856863113,
        11.731314787955313,
        9.418359142233292,
        8.24833870344904,
        10.172119884812323,
        9.725536674232362,
        3.9648043210610626,
        4.102591090710861,
        6.805201941238515,
        4.40587657573994,
        7.76743462310281,
        10.699899407856686,
        6.166901613073036,
        9.279628225722783,
        5.239761647298452,
        11.232285217441243,
        5.85257753000553,
        7.751347601773032,
        10.342790967668966,
        6.13495353940217,
        7.583692696541326,
        10.11701659595116,
        5.7817384092736175,
        5.5513916699995125,
        4.04864832365055,
        5.144820085125078,
        9.30512753800291,
        10.737941819008805,
        10.756905054777066,
        12.775382475573474,
        8.48213078444458,
        5.228523122146712,
        9.133157659142507,
        7.554181264319092,
        6.338580536668529,
        6.2287807853503985,
        9.217082245940897,
        3.491612151372979,
        4.018525570488529,
        8.031047385189932,
        8.13244213595685,
        4.165211184022609,
        7.348692166801709,
        7.624265161969339,
        7.736494228852472,
        9.62401841083848,
        8.83183262226231,
        8.13677574452492,
        10.601534570280474,
        10.83576702090746,
        5.672429550487612,
        9.078222435542362,
        6.5188830180706425,
        8.823379620695393,
        8.767142675939528,
        8.612041467449044,
        8.77810617869089,
        9.789169707276294,
        16.924458561799216,
        11.45695852904064,
        6.503168641938588,
        12.599408450207571,
        6.195070124017076,
        8.457676636112225,
        5.521073607903659,
        8.176938229610379,
        4.277511234804192,
        6.722125997474967,
        10.71961138686256,
        8.158640018895897,
        5.056433969821924,
        7.067764035271472,
        5.5483705896097355,
        7.418532710974753,
        12.823649811604318,
        9.220966790185146,
        7.210821186476772,
        6.208541323480622,
        4.951234071643296,
        11.637556714855661,
        9.594787370709216,
        6.295656039684206,
        10.73854257554275,
        3.187546594218824,
        5.185389154335548,
        10.942391134391821,
        7.076358649080532,
        14.230683125093847,
        5.149703033637452,
        9.306338759567298,
        8.02533261243119,
        10.235064015970503,
        10.997832082545786,
        4.915025652079602,
        8.588287990649121,
        7.437367819046284,
        6.83050333142982,
        11.076297969907362,
        11.156815161026014,
        12.668542380766612,
        4.212205585594778,
        10.304415731787927,
        6.173046231549828,
        4.9742844685626775,
        4.113038080763617,
        7.446733281200669,
        16.005866533980267,
        10.085128419090124,
        5.89503574789095,
        3.2292250407145966,
        12.02497941910497,
        7.5522857869276745,
        12.441125502974158,
        7.080443751382315,
        12.879060433874299,
        5.4681640341035385,
        9.878827123629222,
        13.643858279188969,
        4.260995035265213,
        9.990889747278636,
        9.116175297545228,
        9.478929699432449,
        9.352710479888767,
        11.300416690601457,
        8.491923211228396,
        16.022726894997003,
        8.144590148413485,
        12.0641436374554,
        16.612469704263443,
        5.261723884367359,
        6.725014258671713,
        8.929241295734137,
        4.521282579863617,
        10.86676882437323,
        4.690985227319456,
        9.108120908025144,
        5.486727515863428,
        4.067590835520772,
        7.740623775914916,
        5.801168908529761,
        8.307549603298483,
        10.964651588119054,
        13.509173596584741,
        17.278556821892543,
        7.8768372601252326,
        11.261860225046323,
        5.071501011166894,
        8.042328392330935,
        7.759569772562476,
        6.556328034501602,
        8.277388095652908,
        5.523585874342855,
        10.87589218319233,
        10.63176534580221,
        6.184202752496927,
        7.38681599408467,
        8.924621247336495,
        13.699839604358246,
        8.459789870358376,
        6.589694421022991,
        7.440898543925385,
        2.9248636063188043,
        6.990535889291812,
        11.173089802616229,
        9.161516911195598,
        5.083678527732643,
        6.213571512683348,
        10.393424627437456,
        5.645498174267216,
        2.8772049088235314,
        9.642860331361662,
        8.13668050929983,
        7.758793053087661,
        5.6146408935395185,
        8.470344622429371,
        6.999068144473498,
        7.820321096413583,
        13.281670178917661,
        13.68581897962872,
        8.12691115266803,
        9.959017596930146,
        10.362926034991968,
        4.530486795749562,
        10.677555924584492,
        3.659886765921403,
        10.262244544551036,
        8.521066246313135,
        6.059844676644208,
        4.543242095238496,
        7.898564318880598,
        7.237550812453944,
        4.93825764299291,
        7.019189778911554,
        11.296300279569563,
        4.8593300050991,
        10.257378383266774,
        10.914862218276753,
        7.463415386308178,
        4.900665349111815,
        13.215484661922126,
        4.535778446889051,
        3.5535787410275317,
        7.887259605658136,
        8.553772579958116,
        14.09830347574991,
        5.329351080848945,
        7.919461642373861,
        7.87521841871332,
        5.169545375011609,
        10.654066844548979,
        11.430233692049738,
        5.857438613321158,
        8.798759177523795,
        7.494135673132991,
        6.53141567350059,
        6.552811160308066,
        10.234149290238461,
        3.594757694795361,
        9.086664074693273,
        7.767421522473149,
        5.915371606257899,
        3.577893904100573,
        9.437263923985753,
        9.320137364734146,
        9.125065150912206,
        3.8461645011600836,
        6.653808120910773,
        5.498042478068042,
        8.120346552172254,
        6.494269961126459,
        7.933511978040074,
        13.918955697075331,
        9.507939220973551,
        4.126240773025956,
        7.771673661351733,
        4.556765979295436,
        4.879483119968943,
        8.250811885045753,
        4.469311896123491,
        6.551261027296213,
        7.253825016651789,
        11.083934668028675,
        6.533059190928651,
        8.84668632600371,
        8.369888137003949,
        5.378887779132432,
        10.126795947606162,
        10.129917913651703,
        6.9561842590439396,
        9.84310844394484,
        5.696695260915858,
        14.55496803938639,
        7.122017138953034,
        7.530976745582467,
        4.988398363154546,
        9.14526048839893,
        4.954769088935427,
        14.523370984957271,
        4.7361279847440585,
        6.920796024374143,
        5.586674379731158,
        9.449536439484872,
        4.99176188067525,
        11.213706960752548,
        12.54666058571468,
        5.540745445728875,
        3.8558655895548397,
        14.676887277333341,
        5.436885304960604,
        5.628441936964063,
        11.125706033043747,
        8.749576986553476,
        12.388671980984709,
        14.079349869154134,
        5.835361041974727,
        13.184863700550425,
        5.4261420766871336,
        6.723271442767368,
        9.161676596268881,
        5.737964287569494,
        10.820657831997874,
        7.412084699454432,
        10.213752716164745,
        3.7767736657586273,
        7.737191000023692,
        14.555103499000387,
        8.856614914599193,
        10.166433586286757,
        4.09171849377237,
        2.9902595081321186,
        9.14728662406904,
        6.936645192399319,
        4.138965794111312,
        13.028985901753119,
        9.725244767425744,
        7.477173546936214,
        11.314019665606253,
        6.444117727752388,
        12.657652950183222,
        5.761761467341665,
        7.097464722148602,
        14.755098102072969,
        11.099059075976625,
        9.897017952734032,
        4.956329689075885,
        12.794192298464068,
        13.228102689559009,
        8.066547184770315,
        8.591009669613031,
        6.166204291745967,
        9.332018075483107,
        7.832448381335965,
        16.281328801060987,
        4.309222626952223,
        9.498588906889006,
        10.441840052610285,
        8.263015737660213,
        13.205414974057694,
        7.000652538121805,
        10.960438842171872,
        9.86501324413088,
        8.325199222433882,
        7.796565401584791,
        9.405038009411198,
        4.708398705231464,
        5.872668259199391,
        8.967436138022132,
        5.482600653737601,
        8.076404567875231,
        8.237978234251067,
        6.486761412152937,
        7.456371174380382,
        6.899504798646866,
        8.134836281178455,
        8.450427267938375,
        10.37572938147711,
        8.329209501365204,
        9.316034116350249,
        5.089900774132984,
        13.547930231880366,
        8.743949027326538,
        10.27080791919026,
        8.827734498500359,
        11.216579377581466,
        8.770802595364533,
        6.959338260567971,
        5.444213246989961,
        7.989990794838995,
        10.937846439872516,
        8.180899054922275,
        7.985504875454471,
        3.9843938403151338,
        12.398173348537,
        10.938943301056659,
        8.76999591066873,
        11.032180450535149,
        5.558380711328058,
        10.33245468913021,
        6.880279793785718,
        9.336371605532225,
        7.345464322965891,
        4.30283055649375,
        8.093588193099327,
        7.414258322551102,
        8.266750737236107,
        7.9173137705184455,
        5.750284611119307,
        4.6459089358354495,
        7.6198957636902,
        8.97060630883959,
        5.326274462459209,
        11.967659733146613,
        9.874929485069007,
        10.23159121406426,
        11.836596725253983,
        3.959276574344674,
        6.9954257439192675,
        7.432931311568373,
        9.746641077898841,
        11.825763645753844,
        7.456041496922466,
        4.259065042427834,
        10.07799777517697,
        5.058350373755503,
        10.682762716318358,
        11.087218481116444,
        8.428844861787733,
        7.015737331423942,
        8.303300331564607,
        12.291803255696264,
        4.784685361286167,
        16.51924119846499,
        10.666433961966044,
        11.998390399802215,
        7.506721004066136,
        7.93847449458248,
        6.350372572171508,
        6.289447542266057,
        5.475396137308873,
        5.146280286648387,
        7.70252355005083,
        8.24396218906926,
        8.31854213582824,
        6.14027161427373,
        14.414908966073588,
        13.627104239086506,
        7.679547728661211,
        10.522079907206695,
        6.103708376123742,
        10.092133775149398,
        7.12176247413947,
        12.124816398549887,
        9.975634424378296,
        9.908170619029983,
        6.404544887565034,
        6.272487917003867,
        8.657912967475504,
        8.923941584017273,
        8.874368403757247,
        7.89009991567954,
        9.96564977516247,
        12.119806431433695,
        7.439949133612241,
        5.059589288393998,
        10.992337663939875,
        8.544492541719647,
        8.069286883842462,
        12.142671973907314,
        12.244181376083041,
        7.254731016231157,
        6.9394889069961,
        4.885018278662632,
        3.2027266415278897,
        10.361952592984881,
        8.749014563773336,
        7.226983538376104,
        12.347257693617816,
        6.170174258140129,
        9.568334125286178,
        7.276469114001665,
        8.29248400604578,
        9.836893297438767,
        5.820883892180302,
        11.392698267264084,
        9.650179131558449,
        4.7433123866062115,
        8.785424013312843,
        9.028316448189544,
        5.396759329612624,
        7.990711496939726,
        3.251458951109645,
        7.921743325208458,
        7.137958483742584,
        6.486809450949381,
        3.4464430116026117,
        6.9469827145940295,
        8.481170717659795,
        3.420703934861107,
        8.798323408118172,
        9.484988511863673,
        7.819056099758847,
        3.9157165790620025,
        11.241052815549702,
        10.862720666734068,
        7.802790486875669,
        7.803301660108228,
        11.952909335486439,
        10.744179022695256,
        5.853543688585663,
        5.939032378035184,
        4.409515278583782,
        8.615404947779348,
        14.964683287136888,
        8.006832769491162,
        4.578173864288094,
        7.601118201714651,
        9.502653664756291,
        4.789966007041532,
        12.817938829163683,
        13.229028509191965,
        6.225515069470751,
        9.76704138629219,
        10.352173573020966,
        12.761602165959044,
        7.1707871269876,
        5.1635441448972115,
        3.7690919179429185,
        9.013653558279884,
        4.516976194361965,
        3.7468212501461893,
        2.8874384800789215,
        9.025278493976629,
        7.676221949309425,
        4.77574763972605,
        9.086091169657834,
        7.3179345515780705,
        4.342768272098845,
        3.3600547087336206,
        6.796157196686979,
        12.29449319711167,
        7.735476464360729,
        12.803964778605057,
        7.04426198628358,
        8.335705243912495,
        11.125577842285773,
        10.8198868049981,
        6.207799331735276,
        7.108574108906515,
        8.504856762976651,
        5.4733494286031,
        9.5086502513046,
        10.486547931335942,
        5.932313611319888,
        4.357118413646019,
        5.195632656538489,
        6.290185076692527,
        9.242159096250335,
        8.753405085446586,
        4.687453561252429,
        4.337623728429363,
        4.310011666814851,
    ]
    
    

    const curvesChart = () => (
        <Fragment>
                <Plot
                    data={[
                        {
                            x: testx,
                            y: testy,
                            hovertemplate: 'Portfolio has an average annual return of %{y:.1f}% with %{x:.1f}% volatility<extra></extra>',
                            hoverlabel: { bgcolor: "#444" },
                            mode: 'markers',
                            type: 'scatter',
                            marker:{color: '#adb5bd'},
                        },
                        {
                            x: [19.49, 3.03, 7.66, 7.52],
                            y: [11.64, 3.36, 5.21, 7.25],
                            hoverlabel: { bgcolor: "#444" },
                            mode: 'markers+text',
                            type: 'scatter',
                            marker:{color: '#000000'},
                            text: ['100% in S&P 500', '100% in US T-Bills', '100% in US Treasuries', '100% in US Corporate Bonds'],
                            textposition: 'right center',
                        },
                        {
                            x: [portfolioX],
                            y: [portfolioY],
                            hovertemplate: 'Portfolio has an average annual return of %{y:.1f}% with %{x:.1f}% volatility<extra></extra>',
                            mode: 'markers+text',
                            type: 'scatter',
                            marker:{
                                color: '#0d6efd',
                                size: 12
                            },
                            text: ['Portfolio'],
                            textfont: {
                                size: 16,
                                color: '#0d6efd'
                            },
                            textposition: 'left center',
                        },
                      ]}
                    config={{
                        displayModeBar: false
                    }}
                    layout={{
                        xaxis: {
                            title: "Historical volatility (%)",
                            range: [0, 25],
                            dtick: 5,
                            fixedrange: true
                        },
                        yaxis: {
                            title: "Average historical annual return (%)",
                            range: [0, 13],
                            dtick: 2.5,
                            fixedrange: true
                        },
                        margin: {
                            t: 0,
                            r: 0,
                            l: 50,
                            b: 40
                        },
                        autosize: true,
                        // hovermode: 'x',
                        showlegend: false,
                    }}
                    useResizeHandler
                    className="w-100"
                />

            <p className="mt-5">The grey dots on the chart are example portfolios with different weights for the asset classes below.</p>

            <p>Historical data, from 1928 to 2020, obtained from <a href="http://pages.stern.nyu.edu/~adamodar/New_Home_Page/home.htm">Damodaran Online</a>.</p>
        </Fragment>
    )

    const warningError = () => (
        <Fragment>
            <p className='mt-3 text-danger'>Sum of weights is {sumToggles}%. Please ensure inputs add up to 100%.</p>
        </Fragment>
    )

    const blankFragment = () => (
        <Fragment></Fragment>
    )

    const authenticatedOnlyContent = () => (
        <Fragment>
            <div className="row mw-100 mx-auto pt-3 gy-5 px-3 px-lg-5 gx-lg-5">
                <div className="col-lg">
                    <h1 className="display-4 mb-3">Allocation</h1>

                    <p>You can experiment with an investment portfolio allocation using a model portfolio.  When you move a toggle to the right, you increase the allocated weight to the particular asset class in the portfolio.</p>

                    <p>In real life, you may not have access to the type of asset classes below, however, by playing around with the model portfolio weights, you will gain a better under standing of why diversification is important.</p>

                    <p className='form-label mb-5'>Your aim is to find a portfolio where the combination of weights that is allocated gives you a portfolio that maximizes your return for a given <Link to='/volatility'>volatility</Link>.</p>
                    
                    <form className="mx-auto" style={{ width: "90%" }}>                  
                        <label for="toggleSPX" className="form-label">
                            S&amp;P500 (%): {toggleSPX}%
                        </label>
                        <input
                            className='form-range mb-3'
                            type='range'
                            name='toggleSPX'
                            value={toggleSPX}
                            onChange={e => onChange(e)}
                            min='0'
                            max='100'
                            step='1'
                        />
                        <label for="toggleCorp" className="form-label">
                            US Corporate Bonds (%): {toggleCorp}%
                        </label>
                        <input
                            className='form-range mb-3'
                            type='range'
                            name='toggleCorp'
                            value={toggleCorp}
                            onChange={e => onChange(e)}
                            min='0'
                            max='100'
                            step='1'
                        />
                        <label for="toggleUST" className="form-label">
                            US Treasuries (%): {toggleUST}%
                        </label>
                        <input
                            className='form-range mb-3'
                            type='range'
                            name='toggleUST'
                            value={toggleUST}
                            onChange={e => onChange(e)}
                            min='0'
                            max='100'
                            step='1'
                        />
                        <label for="toggleTbills" className="form-label">
                            US T-Bills (%): {toggleTbills}%
                        </label>
                        <input
                            className='form-range mb-3'
                            type='range'
                            name='toggleTbills'
                            value={toggleTbills}
                            onChange={e => onChange(e)}
                            min='0'
                            max='100'
                            step='1'
                        />
                    </form>
                    <p>{sumToggles === 100 ? blankFragment() : warningError()}</p>
                </div>
                <div className=" col-lg">
                    {curvesChart()}
                </div>
            </div>
        </Fragment>
    )

    const pleaseLogin = () => (
        <Fragment>
            <div className="container pb-5 mb-5 rounded-3">
                <div className="container-fluid py-5 text-center">
                    <h1 className="display-5 fw-bold text-danger">Restricted Access</h1>
                    <p className="col-md-8 fs-4 mx-auto">
                        Please login to start using Cermat.
                    </p>
                    <a className="btn btn-primary btn-lg" href='/login' role='button'>Login</a>
                    <p className='mt-3'>
                        Don't have an Account? <a href='/signup'>Sign Up</a>
                    </p>
                </div>
            </div>
        </Fragment>
    )

    return (
        <Fragment>
            <div className="pb-5 mb-5">
                {/* {isAuthenticated ? authenticatedOnlyContent() : pleaseLogin()} */}
                {isAuthenticated ? authenticatedOnlyContent() : <Redirect to='/' />}
            </div>
        </Fragment>
    )
}


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
})


export default connect(mapStateToProps, { logout, refresh_token })(Allocation)