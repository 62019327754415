import axios from 'axios'
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_FAIL,
    SOCIAL_LOGIN_SUCCESS,
    LOGOUT
} from './types'


export const load_user = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            }
        }

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/user/`, config)
    
            dispatch({
                type: USER_LOADED_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            dispatch({
                type: USER_LOADED_FAIL
            })
        }
    } else {
        dispatch({
            type: USER_LOADED_FAIL
        })
    }
}


export const checkAuthenticated = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }

        const body = JSON.stringify({ token: localStorage.getItem('access') })

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/token/verify/`, body, config)

            if (res.data.code !== 'token_not_valid') {
                dispatch({
                    type: AUTHENTICATED_SUCCESS
                })
            } else {
                dispatch({
                    type: AUTHENTICATED_FAIL
                })
            }
        } catch (err) {
            dispatch({
                type: AUTHENTICATED_FAIL
            })
        }

    } else {
        dispatch({
            type: AUTHENTICATED_FAIL
        })
    }
}


export const login = (email, password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ email, password })

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login/`, body, config)

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });

        dispatch(load_user())
    } catch (err) {
        let loginError = ''

        if (err.response.status >= 500) {
            loginError = 'Login has failed because the server is down please try again later.'
        } else if (err.response.status >= 400) {
            loginError = 'Login has failed because of wrong email and/or password. Alternatively, you can sign in with Google on the main page.'
        } else {
            loginError = ''
        }
        
        dispatch({
            type: LOGIN_FAIL,
            payload: loginError
        })
    }
}


export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    })
}


export const reset_password = (email) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ email })

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/password-reset/`, body, config)

        dispatch({
            type: PASSWORD_RESET_SUCCESS
        })
    } catch (err) {
        let passwordResetError = ''
        
        if (err.response.status >= 500) {
            passwordResetError = 'Password reset has failed because the server is down please try again later.'
        } else if (err.response.status >= 400) {
            passwordResetError = 'Password reset has failed because the email is not associated with any Accounts on the server.'
        } else {
            passwordResetError = ''
        }

        dispatch({
            type: PASSWORD_RESET_FAIL,
            payload: passwordResetError
        })
    }
}


export const reset_password_confirm = (new_password1, new_password2, uid, token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ new_password1, new_password2, uid, token })

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/password-reset-confirm/`, body, config)

        dispatch({
            type: PASSWORD_RESET_CONFIRM_SUCCESS
        })
    } catch (err) {
        let passwordResetConfirmError = ''
        
        if (err.response.status >= 500) {
            passwordResetConfirmError = 'Password reset has failed because the server is down please try again later.'
        } else if (err.response.status >= 400) {
            passwordResetConfirmError = 'Password reset has failed because you have entered a weak password or the passwords entered do not match.'
        } else {
            passwordResetConfirmError = ''
        }

        dispatch({
            type: PASSWORD_RESET_CONFIRM_FAIL,
            payload: passwordResetConfirmError
        })
    }
}


export const refresh_token = () => async dispatch => {
    if (localStorage.getItem('refresh')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }

        const body = JSON.stringify({ refresh: localStorage.getItem('refresh') })
        // const body = JSON.stringify({ refresh: 'x' })

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/token/refresh/`, body, config)

            if (res.data.code !== 'token_not_valid') {
                dispatch({
                    type: REFRESH_TOKEN_SUCCESS,
                    payload: res.data
                })
            } else {
                dispatch({
                    type: REFRESH_TOKEN_FAIL
                })
            }
        } catch (err) {
            dispatch({
                type: REFRESH_TOKEN_FAIL
            })
        }

    } else {
        dispatch({
            type: REFRESH_TOKEN_FAIL
        })
    }
}

export const signup = (email, password1, password2) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ email, password1, password2 })

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register/`, body, config)
        
        dispatch({
            type: SIGNUP_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        let signupError = ''
        
        if (err.response.status >= 500) {
            signupError = 'Signup has failed because the server is down please try again later.'
        } else if (err.response.status >= 400) {
            let errorObject = err.response.data
            let errorKeys = Object.keys(errorObject)
            let errorMessage = ""
            let errorIndex = 1

            for (let i = 0; i < errorKeys.length; i++) {
                let key = errorKeys[i]
                let innerObject = errorObject[key]
                let innerObjectKeys = Object.keys(innerObject)

                for (let j = 0; j < innerObjectKeys.length; j++) {
                    errorMessage += " (" + errorIndex + ") " + innerObject[j]
                    errorIndex += 1
                }
            }

            signupError = "Signup has failed:" + errorMessage
        } else {
            signupError = ''
        }
        
        dispatch({
            type: SIGNUP_FAIL,
            payload: signupError
        })
    }
}

export const social_login = (refresh, access) => dispatch => {
    const data = {
        'refresh_token': refresh,
        'access_token': access
    }

    dispatch({
        type: SOCIAL_LOGIN_SUCCESS,
        payload: data
    })

    dispatch(load_user())
}