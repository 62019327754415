import React, { Fragment, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { social_login } from '../actions/auth'


const Home = ({ social_login, isAuthenticated }) => {
    const openGoogleLoginPage = useCallback(() => {
        const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth'
      
        const scope = [
          'https://www.googleapis.com/auth/userinfo.email',
          'https://www.googleapis.com/auth/userinfo.profile'
        ].join(' ');
      
        const params = {
          response_type: 'code',
          client_id: '567839423873-jfb3lg24feid99oaf6l72arhm3fcpf2i.apps.googleusercontent.com',
          redirect_uri: 'https://cermat.pythonanywhere.com/auth/google',
          prompt: 'select_account',
          access_type: 'offline',
          scope
        }
      
        const urlParams = new URLSearchParams(params).toString();
      
        window.location = `${googleAuthUrl}?${urlParams}`;
    }, []);

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const refresh = urlParams.get('refresh') ? urlParams.get('refresh') : null
    const access = urlParams.get('access') ? urlParams.get('access') : null

    if (refresh) {
        social_login(refresh, access)
    }



    const authenticatedOnlyContent = () => (
        <Fragment>
            <div className="container-fluid my-5 pb-5 text-center" style={{ maxWidth: "40rem" }}>
                <h1 className="display-5 fw-bold">Let's get started</h1>

                <p className="col-md-8 fs-4 mx-auto">
                    Click one of the links below to begin.
                </p>

                <div 
                    className="row gx-2 gy-2"
                    data-for="custom-color"
                    data-tip="Please login or register for access."
                >

                    <div className="col">
                        <div className="card h-100">
                            <div className="card-body d-flex flex-column">
                                <i className="bi bi-cash-coin" style={{ fontSize: "2.5rem" }}></i>
                                <p className="card-text">Figure out how much you need to retire.</p>
                                <div className="d-grid mt-auto">
                                    <Link to="/retirement" className="btn btn-primary">Retirement</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card h-100">
                            <div className="card-body d-flex flex-column">
                                <i class="bi bi-piggy-bank" style={{ fontSize: "2.5rem" }}></i>
                                <p className="card-text">Figure out a savings plan.</p>
                                <div className="d-grid mt-auto">
                                    <Link to="/savings" className="btn btn-primary">Savings</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card h-100">
                            <div className="card-body d-flex flex-column">
                                <i class="bi bi-pie-chart" style={{ fontSize: "2.5rem" }}></i>
                                <p className="card-text">Learn about asset allocation.</p>
                                <div className="d-grid mt-auto">
                                    <Link to="/allocation" className="btn btn-primary">Allocation</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="card h-100">
                            <div className="card-body d-flex flex-column">
                                <i class="bi bi-graph-up" style={{ fontSize: "2.5rem" }}></i>
                                <p className="card-text">Learn about volatility.</p>
                                <div className="d-grid mt-auto">
                                    <Link to="/volatility" className="btn btn-primary">Volatility</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )

    const pleaseLogin = () => (
        <Fragment>
            <div className="container-fluid my-5 text-center" style={{ maxWidth: "40rem" }}>
                <h1 className="display-5 fw-bold">Welcome to Cermat</h1>
                
                <p className="col-md-8 fs-4 mx-auto">
                    A do-it-yourself algorithm-assisted financial planning tool for everyone.
                </p>

                <div className="justify-content-center mt-4">
                    <div 
                        className="row gx-2"
                        data-for="custom-color"
                        data-tip="Please sign in or sign up for access."
                    >
                        <div className="col">
                            <img
                                src="https://images2.imgbox.com/30/b2/4teLVzj2_o.png"
                                alt="Retirement"
                                className="img-fluid rounded border"
                            />
                        </div>

                        <div className="col">
                            <img
                                src="https://images2.imgbox.com/9b/34/pkCmZA7D_o.png"
                                alt="Allocation"
                                className="img-fluid rounded border"
                            />
                        </div>

                        <div className="col">
                            <img
                                src="https://images2.imgbox.com/63/cb/EjYSBAdi_o.png"
                                alt="Volatility"
                                className="img-fluid rounded border"
                            />
                        </div>
                    </div>

                    <ReactTooltip
                        id="custom-color"
                        className="custom-color"
                        textColor="#FFF"
                        backgroundColor="#444"
                        border
                    />
                </div>
                
                {/* <div className="d-grid mx-auto justify-content-center">
                    <a className="btn btn-primary btn-lg mt-5" href='/login' role='button'>
                        Login
                    </a>
                </div> */}

                <div className ="row mx-auto" style={{ maxWidth: "30rem" }}>
                    <a className="btn btn-outline-primary btn-lg btn-block mt-5" href='#' onClick={ openGoogleLoginPage } role='button'>
                        <img className="align-text-bottom" src='https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg' height="26px" alt="Google logo"></img>
                        <span style={{ marginLeft: "34px" }}>Sign in with Google</span>
                    </a>

                    <a className="btn btn-primary btn-lg btn-block mt-3" href='/login' role='button'>
                        Sign in with email and password
                    </a>
                </div>
                    
                {/* <p className='mt-3 pb-5'>
                    Don't have an account? <a href='/signup'>Sign up</a>.
                </p> */}
            </div>
        </Fragment>
    )

    return (
        <div>
            {isAuthenticated ? authenticatedOnlyContent() : pleaseLogin()}
        </div>
    )
}


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})


export default connect(mapStateToProps, { social_login })(Home)
