import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_FAIL,
    SOCIAL_LOGIN_SUCCESS,
    LOGOUT
} from '../actions/types'


const initialState = {
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isAuthenticated: null,
    user: null,
    loginError: '',
    signupError: '',
    signupSuccessful: null,
    passwordResetError: '',
    passwordResetConfirmError: '',
    passwordResetConfirmSuccessful: null,
    passwordResetSuccessful: null,
}


export default function(state = initialState, action) {
    const { type, payload } = action

    switch(type) {
        case AUTHENTICATED_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                loginError: '',
                signupError: '',
                signupSuccessful: null,
                passwordResetError: '',
                passwordResetConfirmError: '',
                passwordResetConfirmSuccessful: null,
                passwordResetSuccessful: null,
            }

        case LOGIN_SUCCESS:
        case SOCIAL_LOGIN_SUCCESS:
            localStorage.setItem('access', payload.access_token)
            localStorage.setItem('refresh', payload.refresh_token)
            return {
                ...state,
                isAuthenticated: true,
                access: payload.access_token,
                refresh: payload.refresh_token,
                loginError: '',
                signupError: '',
                signupSuccessful: null,
                passwordResetError: '',
                passwordResetConfirmError: '',
                passwordResetConfirmSuccessful: null,
                passwordResetSuccessful: null,
            }

        case SIGNUP_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                loginError: '',
                signupError: '',
                signupSuccessful: true,
                passwordResetError: '',
                passwordResetConfirmError: '',
                passwordResetConfirmSuccessful: null,
                passwordResetSuccessful: null,
            }

        case USER_LOADED_SUCCESS:
            return {
                ...state,
                user: payload,
                loginError: '',
                signupError: '',
                signupSuccessful: null,
                passwordResetError: '',
                passwordResetConfirmError: '',
                passwordResetConfirmSuccessful: null,
                passwordResetSuccessful: null,
            }

        case AUTHENTICATED_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                loginError: '',
                signupError: '',
                signupSuccessful: null,
                passwordResetError: '',
                passwordResetConfirmError: '',
                passwordResetConfirmSuccessful: null,
                passwordResetSuccessful: null,
            }

        case USER_LOADED_FAIL:
            return {
                ...state,
                user: null,
                loginError: '',
                signupError: '',
                signupSuccessful: null,
                passwordResetError: '',
                passwordResetConfirmError: '',
                passwordResetConfirmSuccessful: null,
                passwordResetSuccessful: null,
            }
        
        case LOGOUT:
            localStorage.removeItem('access')
            localStorage.removeItem('refresh')
            return {
                ...state,
                access: null,
                refresh: null,
                isAuthenticated: false,
                user: null,
                loginError: '',
                signupError: '',
                signupSuccessful: null,
                passwordResetError: '',
                passwordResetConfirmError: '',
                passwordResetConfirmSuccessful: null,
                passwordResetSuccessful: null,
            }

        case LOGIN_FAIL:
            localStorage.removeItem('access')
            localStorage.removeItem('refresh')
            return {
                ...state,
                access: null,
                refresh: null,
                isAuthenticated: false,
                user: null,
                loginError: payload,
                signupError: '',
                signupSuccessful: null,
                passwordResetError: '',
                passwordResetConfirmError: '',
                passwordResetConfirmSuccessful: null,
                passwordResetSuccessful: null,
            }

        case SIGNUP_FAIL:
            localStorage.removeItem('access')
            localStorage.removeItem('refresh')
            return {
                ...state,
                access: null,
                refresh: null,
                isAuthenticated: false,
                user: null,
                loginError: '',
                signupError: payload,
                signupSuccessful: null,
                passwordResetError: '',
                passwordResetConfirmError: '',
                passwordResetConfirmSuccessful: null,
                passwordResetSuccessful: null,
            }

        case PASSWORD_RESET_FAIL:
            return {
                ...state,
                loginError: '',
                signupError: '',
                signupSuccessful: null,
                passwordResetError: payload,
                passwordResetConfirmError: '',
                passwordResetConfirmSuccessful: null,
                passwordResetSuccessful: null,
            }

        case PASSWORD_RESET_CONFIRM_FAIL:
            return {
                ...state,
                loginError: '',
                signupError: '',
                signupSuccessful: null,
                passwordResetError: '',
                passwordResetConfirmError: payload,
                passwordResetConfirmSuccessful: null,
                passwordResetSuccessful: null,
            }

        case PASSWORD_RESET_CONFIRM_SUCCESS:
            return {
                ...state,
                loginError: '',
                signupError: '',
                signupSuccessful: null,
                passwordResetError: '',
                passwordResetConfirmError: '',
                passwordResetConfirmSuccessful: true,
                passwordResetSuccessful: null,
            }
        
        case PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                loginError: '',
                signupError: '',
                signupSuccessful: null,
                passwordResetError: '',
                passwordResetConfirmError: '',
                passwordResetConfirmSuccessful: null,
                passwordResetSuccessful: true,
            }

        case REFRESH_TOKEN_SUCCESS:
            localStorage.setItem('access', payload.access)
            return {
                ...state,
                access: payload.access,
            }

        case REFRESH_TOKEN_FAIL:
            return {
                ...state,
            }

        default:
            return state
    }
}