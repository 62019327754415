import React, { useState, Fragment } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout, refresh_token } from '../actions/auth'
import axios from 'axios'
import Plot from 'react-plotly.js'


const Savings = ({ isAuthenticated, refresh_token }) => {
    const [formData, setFormData] = useState({
        current_accumulated_savings: 100000,
        annual_savings: 20000,
        investment_horizon: 30,
        investment_return_mu: 10,
        investment_return_sigma: 15
    })

    const [answer, setAnswer] = useState(0)

    const [loading, setLoading] = useState(false)

    const {
        current_accumulated_savings, 
        annual_savings,
        investment_horizon,
        investment_return_mu,
        investment_return_sigma
    } = formData

    const savings_list = answer

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    async function getSavingsList() {
        await refresh_token()

        if (localStorage.getItem('access')) {
            const config = {headers: {'Authorization': `Bearer ${localStorage.getItem('access')}`}}

            try {
                setLoading(true)
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/retirement/savings/?current_accumulated_savings=${current_accumulated_savings}&annual_savings=${annual_savings}&investment_horizon=${investment_horizon}&investment_return_mu=${investment_return_mu}&investment_return_sigma=${investment_return_sigma}`, config)
                setAnswer(response.data.result)
                setLoading(false)
            }

            catch(err) {
                console.log(err.response)
            }

        }
        else {
            console.log("failed to authenticate")
            logout()
        }
    }

    const onSubmit = e => {
        e.preventDefault()
        getSavingsList()
    }

    const loader = () => {
        return (
            <Fragment>
                <div className="h-100 d-flex justify-content-center my-4">
                    <div className="spinner-border align-self-center" role="status"
                        style={{
                            border: "30px solid #dee2e6", /* Light grey */
                            borderTop: "30px solid #0d6efd", /* Blue */
                            borderRadius: "50%",
                            width: "270px",
                            height: "270px",
                        }}
                    >
                    </div>
                </div>
            </Fragment>
        )
    }

    const savingsChart = () => (
        <Fragment>
            <p className='form-label'>Hover over the chart to see whether your current savings and planned yearly savings are adequate to meet your <Link to='/retirement'>target</Link>.</p>

            <Plot
                data={[
                    {
                        x: savings_list,
                        hovertemplate: '%{y:.1f}% probability that savings will be at least %{x}<extra></extra>',
                        hoverlabel: { bgcolor: "#444" },
                        type: 'histogram',
                        histnorm: 'percent',
                        cumulative: {enabled: true, direction: 'decreasing'},
                        marker:{color: '#0d6efd'},
                        autobinx: false,
                        xbins: {
                            end: current_accumulated_savings * 50
                        }
                    },
                    ]}
                config={{
                    displayModeBar: false
                }}
                layout={{
                    xaxis: {
                        title: "Savings",
                        fixedrange: true
                    },
                    yaxis: {
                        title: "Probability that future savings will be at least x (%)",
                        range: [0, 105],
                        dtick: 10,
                        fixedrange: true
                    },
                    margin: {
                        t: 0,
                        r: 0,
                        l: 50,
                        b: 40
                    },
                    autosize: true,
                    hovermode: 'x',
                }}
                useResizeHandler
                className="w-100"
            />
        </Fragment>
    )

    const authenticatedOnlyContent = () => (
        <Fragment>
            <div className="row mw-100 mx-auto pt-3 gy-5 px-3 px-lg-5 gx-lg-5">
                <div className="col-lg">

                    <h1 className="display-4 mb-3">Savings</h1>
                
                    <p>This calculator calculates how much savings you will have in the future.</p>

                    <p>Your savings will earn the returns at the rate of return that you specify.</p>

                    <p className="mb-5">However, return will not be the same for every year, thus you need to also specify its volatility.</p>

                    <form className="mx-auto" style={{ width: "90%" }} onSubmit={e => onSubmit(e)}>
                        <div>
                            <label for="current_accumulated_savings" className="form-label">Current savings: {new Intl.NumberFormat().format(current_accumulated_savings)}</label>
                            <input
                                className='form-range mb-3'
                                type='range'
                                name='current_accumulated_savings'
                                value={current_accumulated_savings}
                                onChange={e => onChange(e)}
                                min='0'
                                max='5000000'
                                step='1000'
                            />
                            <label for="annual_savings" className="form-label">Planned yearly savings: {new Intl.NumberFormat().format(annual_savings)}</label>
                            <input
                                className='form-range mb-3'
                                type='range'
                                name='annual_savings'
                                value={annual_savings}
                                onChange={e => onChange(e)}
                                min='1000'
                                max='500000'
                                step='100'
                            />
                            <label for="investment_horizon" className="form-label">Years to retirement: {investment_horizon}</label>
                            <input
                                className='form-range mb-3'
                                type='range'
                                name='investment_horizon'
                                value={investment_horizon}
                                onChange={e => onChange(e)}
                                min='0'
                                max='50'
                                step='1'
                            />
                            <label for="investment_return_mu" className="form-label">Expected average annual <Link to='/allocation'>return</Link>: {investment_return_mu}%</label>
                            <input
                                className='form-range mb-3'
                                type='range'
                                name='investment_return_mu'
                                value={investment_return_mu}
                                onChange={e => onChange(e)}
                                min='0'
                                max='20'
                                step='.25'
                            />
                            <label for="investment_return_sigma" className="form-label">Expected <Link to='/volatility'>volatility</Link> of return: {investment_return_sigma}%</label>
                            <input
                                className='form-range mb-4'
                                type='range'
                                name='investment_return_sigma'
                                value={investment_return_sigma}
                                onChange={e => onChange(e)}
                                min='0'
                                max='20'
                                step='.25'
                            />
                        </div>

                        <div className="d-grid">
                            <button className='btn btn-primary' type='submit'>Run Simulation</button>
                        </div>
                    </form>
                </div>

                <div className=" col-lg">
                    {loading ? loader() : savingsChart()}
                </div>
            </div>
        </Fragment>
    )

    const pleaseLogin = () => (
        <Fragment>
            <div className="container pb-5 mb-5 rounded-3">
                <div className="container-fluid py-5 text-center">
                    <h1 className="display-5 fw-bold text-danger">Restricted Access</h1>
                    <p className="col-md-8 fs-4 mx-auto">
                        Please login to start using Cermat.
                    </p>
                    <a className="btn btn-primary btn-lg" href='/login' role='button'>Login</a>
                    <p className='mt-3'>
                        Don't have an Account? <a href='/signup'>Sign Up</a>
                    </p>
                </div>
            </div>
        </Fragment>
    )

    return (
        <Fragment>
            <div className="pb-5 mb-5">
                {/* {isAuthenticated ? authenticatedOnlyContent() : pleaseLogin()} */}
                {isAuthenticated ? authenticatedOnlyContent() : <Redirect to='/' />}
            </div>
        </Fragment>
    )
}


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
})


export default connect(mapStateToProps, { logout, refresh_token })(Savings)