import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
import Layout from './hocs/Layout'
import Home from './containers/Home'
import Login from './containers/Login'
import Signup from './containers/Signup'
import ResetPassword from './containers/ResetPassword'
import ResetPasswordConfirm from './containers/ResetPasswordConfirm'
import Retirement from './containers/Retirement'
import Savings from './containers/Savings'
import Volatility from './containers/Volatility'
import Allocation from './containers/Allocation'


const App = () => (
  <Provider store={store}>
    <Router>
      <Layout>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/signup' component={Signup} />
          <Route exact path='/password-reset' component={ResetPassword} />
          <Route exact path='/password-reset-confirm/:uid/:token' component={ResetPasswordConfirm} />
          <Route exact path='/retirement' component={Retirement} />
          <Route exact path='/savings' component={Savings} />
          <Route exact path='/volatility' component={Volatility} />
          <Route exact path='/allocation' component={Allocation} />
        </Switch>
      </Layout>
    </Router>
  </Provider>
)


export default App
