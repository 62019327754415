import React, { useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { reset_password } from '../actions/auth'


const ResetPassword = ({ reset_password, passwordResetError, passwordResetSuccessful }) => {
    const [formData, setFormData] = useState({
        email: ''
    })

    const { email } = formData

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = e => {
        e.preventDefault()

        reset_password(email)
    }

    const thereIsPasswordResetError = () => (
        <Fragment>
            <p className='mt-3 text-danger'>{passwordResetError}</p>
        </Fragment>
    )

    const blankFragment = () => (
        <Fragment>
        </Fragment>
    )

    const thereIsPasswordResetSuccess = () => (
        <Fragment>
            <p className='mt-3 text-success'>Password reset email sent, please check your inbox.</p>
        </Fragment>
    )

    return (
        <div className='container my-5 pb-5' style={{maxWidth: "20rem"}}>
            <h1>Password Reset</h1>
            <p>Reset your password.</p>
            <form onSubmit={e => onSubmit(e)}>
                <div className='form-group mb-3'>
                    <input
                        className='form-control'
                        type='email'
                        placeholder='Email'
                        name='email'
                        value={email}
                        onChange={e => onChange(e)}
                        required
                    />
                </div>

                <div className="d-grid mb-5">
                    <button className='btn btn-primary' type='submit'>Reset Password</button>
                </div>
                
                {passwordResetError ? thereIsPasswordResetError() : blankFragment()}
                {passwordResetSuccessful ? thereIsPasswordResetSuccess() : blankFragment()}
            </form>
        </div>
    )
}

const mapStateToProps = state => ({
    passwordResetError: state.auth.passwordResetError,
    passwordResetSuccessful: state.auth.passwordResetSuccessful
})

export default connect(mapStateToProps, { reset_password })(ResetPassword)