import React, { useState, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { login } from '../actions/auth'


const Login = ({ login, isAuthenticated, loginError }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    })

    const { email, password } = formData

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = e => {
        e.preventDefault()
        login(email, password)
    }

    if (isAuthenticated) {
        return <Redirect to='/' />
    }

    const thereIsLoginError = () => (
        <Fragment>
            <p className='mt-3 text-danger'>{loginError}</p>
        </Fragment>
    )

    const noLoginError = () => (
        <Fragment>
        </Fragment>
    )

    return (
        <div className='container my-5 pb-5' style={{maxWidth: "20rem"}}>
            <h1>Sign In</h1>
            <p>Sign into your account.</p>
            <form onSubmit={e => onSubmit(e)}>
                <div className="mb-2">
                    <input
                        className='form-control'
                        type='email'
                        placeholder='Email'
                        name='email'
                        value={email}
                        onChange={e => onChange(e)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <input
                        className='form-control'
                        type='password'
                        placeholder='Password'
                        name='password'
                        value={password}
                        onChange={e => onChange(e)}
                        minLength='6'
                        required
                    />
                </div>
                <div className="d-grid">
                    <button className='btn btn-primary' type='submit'>Login</button>
                </div>
            </form>
            {loginError ? thereIsLoginError() : noLoginError()}
            <p className='mt-3'>
                Don't have an account? <a href='/signup'>Sign up</a>.
            </p>
            <p className='mt-3 mb-5'>
                Forgot your password? <a href='/password-reset'>Reset password</a>.
            </p>
        </div>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    loginError: state.auth.loginError
})

export default connect(mapStateToProps, { login })(Login)