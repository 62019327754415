import React, { useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { reset_password_confirm } from '../actions/auth'


const ResetPasswordConfirm = ({ match, reset_password_confirm, passwordResetConfirmError, passwordResetConfirmSuccessful }) => {
    const [formData, setFormData] = useState({
        new_password1: '',
        new_password2: ''
    })

    const { new_password1, new_password2 } = formData

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = e => {
        console.log('submitted')
        e.preventDefault()

        const uid = match.params.uid
        const token = match.params.token

        console.log('new_password1: ' + new_password1)
        console.log('new_password2: ' + new_password2)
        console.log('uid: ' + uid)
        console.log('token: ' + token)

        reset_password_confirm(new_password1, new_password2, uid, token)
    }

    const thereIsPasswordResetConfirmError = () => (
        <Fragment>
            <p className='mt-3 text-danger'>{passwordResetConfirmError}</p>
        </Fragment>
    )

    const thereIsPasswordResetConfirmSuccess = () => (
        <Fragment>
            <p className='mt-3 text-success'>
                Password reset is successful. <Link to="/login">Sign In</Link>.
            </p>
        </Fragment>
    )

    const blankFragment = () => (
        <Fragment>
        </Fragment>
    )

    return (
        <div className='container my-5 pb-5' style={{maxWidth: "20rem"}}>
            <h1>Password Reset</h1>
            <p>Enter your new password.</p>
            <form onSubmit={e => onSubmit(e)}>
                <div className='form-group mb-2'>
                    <input
                        className='form-control'
                        type='password'
                        placeholder='New Password'
                        name='new_password1'
                        value={new_password1}
                        onChange={e => onChange(e)}
                        minLength='6'
                        required
                    />
                </div>
                <div className='form-group mb-3'>
                    <input
                        className='form-control'
                        type='password'
                        placeholder='Confirm New Password'
                        name='new_password2'
                        value={new_password2}
                        onChange={e => onChange(e)}
                        minLength='6'
                        required
                    />
                </div>
                <div className="d-grid mb-5">
                    <button className='btn btn-primary' type='submit'>Reset Password</button>
                </div>
                {passwordResetConfirmError ? thereIsPasswordResetConfirmError() : blankFragment()}
                {passwordResetConfirmSuccessful ? thereIsPasswordResetConfirmSuccess() : blankFragment()}
            </form>
        </div>
    )
}

const mapStateToProps = state => ({
    passwordResetConfirmError: state.auth.passwordResetConfirmError,
    passwordResetConfirmSuccessful: state.auth.passwordResetConfirmSuccessful
})

export default connect(mapStateToProps, { reset_password_confirm })(ResetPasswordConfirm)