import React, { useState, Fragment } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout, refresh_token } from '../actions/auth'
import axios from 'axios'
import Plot from 'react-plotly.js'


const Retirement = ({ isAuthenticated, refresh_token }) => {
    const [formData, setFormData] = useState({
        retirement_savings: 1000000,
        first_year_spending: 60000,
        inflation_mu: 3,
        inflation_sigma: 3,
        investment_return_mu: 10,
        investment_return_sigma: 10
    })

    const [answer, setAnswer] = useState(0)

    const [loading, setLoading] = useState(false)

    const {
        retirement_savings,
        first_year_spending,
        inflation_mu,
        inflation_sigma,
        investment_return_mu,
        investment_return_sigma
    } = formData

    const retirement_list = answer

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    async function getRetirementList() {
        await refresh_token()
        
        if (localStorage.getItem('access')) {
            const config = {headers: {'Authorization': `Bearer ${localStorage.getItem('access')}`}}

            try {
                setLoading(true)
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/retirement/retirement/?retirement_savings=${retirement_savings}&first_year_spending=${first_year_spending}&inflation_mu=${inflation_mu}&inflation_sigma=${inflation_sigma}&investment_return_mu=${investment_return_mu}&investment_return_sigma=${investment_return_sigma}`, config)
                setAnswer(response.data.result)
                setLoading(false)
            }

            catch(err) {
                console.log(err.response)
            }

        }

        else {
            console.log("failed to authenticate")
            logout()
        }
    }

    const onSubmit = e => {
        e.preventDefault()
        getRetirementList()
    }

    const loader = () => {
        return (
            <Fragment>
                <div className="h-100 d-flex justify-content-center my-4">
                    <div className="spinner-border align-self-center" role="status"
                        style={{
                            border: "30px solid #dee2e6", /* Light grey */
                            borderTop: "30px solid #0d6efd", /* Blue */
                            borderRadius: "50%",
                            width: "270px",
                            height: "270px",
                        }}
                    >
                    </div>
                </div>
            </Fragment>
        )
    }

    const retirementChart = () => (
        <Fragment>
            <p>Hover over the chart to figure out the amount that you need to save for retirement then click <Link to='/savings'>here</Link> to figure out a savings/investment plan.</p>
            
            <Plot
                data={[
                    {
                        x: retirement_list,
                        hovertemplate: '%{y:.1f}% probability that savings will last at least %{x} years<extra></extra>',
                        hoverlabel: { bgcolor: "#444" },
                        type: 'histogram',
                        histnorm: 'percent',
                        cumulative: {enabled: true, direction: 'decreasing'},
                        marker:{color: '#0d6efd'}
                    },
                    ]}
                config={{
                    displayModeBar: false
                }}
                layout={{
                    xaxis: {
                        title: "Years",
                        range: [, 51],
                        fixedrange: true,
                        dtick: 5
                    },
                    yaxis: {
                        title: "Probability that savings will last at least x years (%)",
                        range: [0, 105],
                        fixedrange: true,
                        dtick: 10
                    },
                    margin: {
                        t: 0,
                        r: 0,
                        l: 50,
                        b: 40
                    },
                    autosize: true,
                    // height: 480,
                    hovermode: 'x',
                }}
                useResizeHandler
                className="w-100"
            />
        </Fragment>
    )

    const authenticatedOnlyContent = () => (
        <Fragment>
            <div className="row mw-100 mx-auto pt-3 gy-5 px-3 px-lg-5 gx-lg-5">
                <div className="col-lg">
                    <h1 className="display-4 mb-3">Retirement</h1>

                    <p>This calculator calculates how much you will need in retirement.</p>

                    <p>Your spending in the first year will grow at the rate of the inflation that you specify.  Similarly your retirement savings will continue to earn the returns at the rate of return that you also specify.</p>

                    <p className="mb-5">However, both inflation and return will not be the same for every year, thus you need to also specify the volatility for both.</p>

                    <form className="mx-auto" style={{ width: "90%" }} onSubmit={e => onSubmit(e)}>
                        <div>
                            <label for="retirement_savings" className="form-label">Estimated retirement savings: {new Intl.NumberFormat().format(retirement_savings)}</label>
                            <input
                                className='form-range mb-3'
                                type='range'
                                name='retirement_savings'
                                value={retirement_savings}
                                onChange={e => onChange(e)}
                                min='100000'
                                max='5000000'
                                step='1000'
                            />

                            <label for="first_year_spending" className="form-label">Estimated spending in the first year: {new Intl.NumberFormat().format(first_year_spending)}</label>
                            <input
                                className='form-range mb-3'
                                type='range'
                                name='first_year_spending'
                                value={first_year_spending}
                                onChange={e => onChange(e)}
                                min='10000'
                                max='500000'
                                step='100'
                            />

                            <label for="inflation_mu" className="form-label">Expected average annual inflation: {inflation_mu}%</label>
                            <input
                                className='form-range mb-3'
                                type='range'
                                name='inflation_mu'
                                value={inflation_mu}
                                onChange={e => onChange(e)}
                                min='0'
                                max='10'
                                step='.25'
                            />

                            <label for="inflation_sigma" className="form-label">Expected volatility of inflation: {inflation_sigma}%</label>
                            <input
                                className='form-range mb-3'
                                type='range'
                                name='inflation_sigma'
                                value={inflation_sigma}
                                onChange={e => onChange(e)}
                                min='0'
                                max='10'
                                step='.25'
                            />

                            <label for="investment_return_mu" className="form-label">Expected average annual <Link to='/allocation'>return</Link>: {investment_return_mu}%</label>
                            <input
                                className='form-range mb-3'
                                type='range'
                                name='investment_return_mu'
                                value={investment_return_mu}
                                onChange={e => onChange(e)}
                                min='0'
                                max='20'
                                step='.25'
                            />

                            <label for="investment_return_sigma" className="form-label">Expected <Link to='/volatility'>volatility</Link> of return: {investment_return_sigma}%</label>
                            <input
                                className='form-range mb-4'
                                type='range'
                                name='investment_return_sigma'
                                value={investment_return_sigma}
                                onChange={e => onChange(e)}
                                min='0'
                                max='20'
                                step='.25'
                            />
                        </div>

                        <div className="d-grid">
                            <button className='btn btn-primary' type='submit'>Run Simulation</button>
                        </div>
                    </form>
                </div>

                <div className="col-lg">
                    {loading ? loader() : retirementChart()}
                </div>
            </div>
        </Fragment>
    )

    const pleaseLogin = () => (
        <Fragment>
            <div className="container pb-5 mb-5 rounded-3">
                <div className="container-fluid py-5 text-center">
                    <h1 className="display-5 fw-bold text-danger">Restricted Access</h1>
                    <p className="col-md-8 fs-4 mx-auto">
                        Please login to start using Cermat.
                    </p>
                    <a className="btn btn-primary btn-lg" href='/login' role='button'>Login</a>
                    <p className='mt-3'>
                        Don't have an Account? <a href='/signup'>Sign Up</a>
                    </p>
                </div>
            </div>
        </Fragment>
    )

    return (
        <Fragment>
            <div className="pb-5 mb-5">
                {/* {isAuthenticated ? authenticatedOnlyContent() : pleaseLogin()} */}
                {isAuthenticated ? authenticatedOnlyContent() : <Redirect to='/' />}
            </div>
        </Fragment>
    )
}


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
})


export default connect(mapStateToProps, { logout, refresh_token })(Retirement)
