import React, { useState, useEffect, Fragment } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout, refresh_token } from '../actions/auth'
import Plot from 'react-plotly.js'


const Volatility = ({ isAuthenticated }) => {
    const [data, setData] = useState({
        mu1: 10,
        sigma1: 10,
    })

    const {mu1, sigma1} = data

    const [x1, setX1] = useState([])
    const [y1, setY1] = useState([])

    function gaussian(x, mu, sigma) {
        let exponent = -0.5 * Math.pow( (x - mu) / sigma , 2)
		return Math.exp(exponent)*(1 / sigma) * (1/(Math.sqrt(2 * Math.PI)))
    }

    function updateCurves() {
        let x1u = []
        let y1u = []
        for (let a = -50; a <= 50; a += .1) {
            x1u.push(a)
            y1u.push(gaussian(a, mu1, sigma1) * 100)
        }       
        setX1(x1u)
        setY1(y1u)
    }

    const onChange = e => setData({ ...data, [e.target.name]: e.target.value })

    useEffect(() => {
        updateCurves()
    }, [data])

    function makeCurve(mu, sigma, xArray, yArray) {
        for (let a = (mu - 4 * sigma); a <= (mu + 4 * sigma); a += .1) {
            xArray.push(a)
            yArray.push(gaussian(a, mu, sigma) * 100)
        }
    }

    let x2 = []
    let y2 = []
    makeCurve(0, 1, x2, y2)    

    let sp500x = []
    let sp500y = []
    makeCurve(11.64, 19.49, sp500x, sp500y)

    let corpX = []
    let corpY = []
    makeCurve(7.25, 7.52, corpX, corpY)

    let ustX = []
    let ustY = []
    makeCurve(5.21, 7.66, ustX, ustY)

    let tbillsX = []
    let tbillsY = []
    makeCurve(3.36, 3.03, tbillsX, tbillsY)

    const volTable = () => (
        <table class="table table-hover mt-5">
            <thead>
                <tr>
                <th scope="row">Asset class</th>
                <th className="text-center">Average annual return</th>
                <th className="text-center">Volatility of annual return</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <th scope="row">S&amp;P 500</th>
                    <td className="text-center">11.64%</td>
                    <td className="text-center">19.49%</td>
                </tr>

                <tr>
                    <th scope="row">US Corporate Bonds</th>
                    <td className="text-center">7.25%</td>
                    <td className="text-center">7.52%</td>
                </tr>

                <tr>
                    <th scope="row">US Treasuries</th>
                    <td className="text-center">5.21%</td>
                    <td className="text-center">7.66%</td>
                </tr>

                <tr>
                    <th scope="row">US T-Bills</th>
                    <td className="text-center">3.36%</td>
                    <td className="text-center">3.03%</td>
                </tr>
            </tbody>

        </table>
    )

    const curvesChart = () => (
        <Fragment>
            <Plot
                data={[
                    {
                        x: sp500x,
                        y: sp500y,
                        name: "S&P 500",
                        type: 'scatter',
                        marker:{color: '#dc3545'},
                        fill: 'tozeroy',
                        // fillcolor: '#dc3545',
                        // mode: 'none'
                    },
                    {
                        x: corpX,
                        y: corpY,
                        name: "US Corporate Bonds",
                        // hovertemplate: '%{y:.1f}% probability that value equals %{x:.1f}<extra></extra>',
                        // hoverlabel: { bgcolor: "#444" },
                        type: 'scatter',
                        marker:{color: '#fd7e14'},
                        fill: 'tozeroy',
                        // fillcolor: '#fd7e14',
                        // mode: 'none'
                    },
                    {
                        x: ustX,
                        y: ustY,
                        name: "US Treasuries",
                        // hovertemplate: '%{y:.1f}% probability that value equals %{x:.1f}<extra></extra>',
                        // hoverlabel: { bgcolor: "#444" },
                        type: 'scatter',
                        marker:{color: '#ffc107'},
                        fill: 'tozeroy',
                        // fillcolor: '#ffc107',
                        // mode: 'none'
                    },
                    {
                        x: tbillsX,
                        y: tbillsY,
                        name: "US T-Bills",
                        // hovertemplate: '%{y:.1f}% probability that value equals %{x:.1f}<extra></extra>',
                        // hoverlabel: { bgcolor: "#444" },
                        type: 'scatter',
                        marker:{color: '#198754'},
                        fill: 'tozeroy',
                        // fillcolor: '#198754',
                        // mode: 'none'
                    },
                    {
                        x: x1,
                        y: y1,
                        name: "Simulation",
                        hovertemplate: '%{y:.1f}% probability that value equals %{x:.1f}<extra></extra>',
                        type: 'scatter',
                        marker:{color: '#0d6efd'},
                        fill: 'tozeroy'
                    },
                    ]}
                config={{
                    displayModeBar: false
                }}
                layout={{
                    xaxis: {
                        title: "Annual return (%)",
                        range: [-30, 30],
                        // dtick: 5,
                        fixedrange: true
                    },
                    yaxis: {
                        title: "Probability of annual return (%)",
                        // range: [0, 100],
                        fixedrange: true
                    },
                    margin: {
                        t: 0,
                        r: 0,
                        l: 50,
                        b: 40
                    },
                    autosize: true,
                    // hovermode: 'x',
                    // showlegend: false,
                    legend: {
                        x: 0.01,
                        y: 0.99,
                        xanchor: "left",
                        // orientation: "h",
                        bgcolor: "transparent",
                    },
                }}
                useResizeHandler
                className="w-100"
            />
        </Fragment>
    )

    const authenticatedOnlyContent = () => (
        <Fragment>
            <div className="row mw-100 mx-auto pt-3 gy-5 px-3 px-lg-5 gx-lg-5">
                <div className="col-lg">
                    <h1 className="display-4 mb-3">Volatility</h1>

                    <p>Volatility is the degree of variation of a value that is measured over time; the higher the variation of the value, the higher the volatility.</p>

                    <p>An <Link to='/allocation'>investment</Link> that has a higher volatility also has a higher risk compared to another investment that has lower volatility because the chance of losing money is higher.</p>
                    
                    <p className="mb-5">When you increase the average return, you move the curve to the right.  When you increase the volatility of returns, the curve flattens.</p>
                    
                    <form className="mx-auto" style={{ width: "90%" }}>                  
                        <label for="mu1" className="form-label">Average return: {mu1}%</label>
                        <input
                            className='form-range mb-3'
                            type='range'
                            name='mu1'
                            value={mu1}
                            onChange={e => onChange(e)}
                            min='0'
                            max='20'
                            step='.25'
                        />
                        <label for="sigma1" className="form-label">Volatility of returns: {sigma1}%</label>
                        <input
                            className='form-range mb-3'
                            type='range'
                            name='sigma1'
                            value={sigma1}
                            onChange={e => onChange(e)}
                            min='0'
                            max='20'
                            step='.25'
                        />
                    </form>
                </div>
                <div className=" col-lg">
                    {curvesChart()}
                    {volTable()}
                    <p className="mt-5 mb-0">Historical data, from 1928 to 2020, obtained from <a href="http://pages.stern.nyu.edu/~adamodar/New_Home_Page/home.htm">Damodaran Online</a>.</p>
                </div>
            </div>
        </Fragment>
    )

    const pleaseLogin = () => (
        <Fragment>
            <div className="container pb-5 mb-5 rounded-3">
                <div className="container-fluid py-5 text-center">
                    <h1 className="display-5 fw-bold text-danger">Restricted Access</h1>
                    <p className="col-md-8 fs-4 mx-auto">
                        Please login to start using Cermat.
                    </p>
                    <a className="btn btn-primary btn-lg" href='/login' role='button'>Login</a>
                    <p className='mt-3'>
                        Don't have an Account? <a href='/signup'>Sign Up</a>
                    </p>
                </div>
            </div>
        </Fragment>
    )

    return (
        <Fragment>
            <div className="pb-5 mb-5">
                {/* {isAuthenticated ? authenticatedOnlyContent() : pleaseLogin()} */}
                {isAuthenticated ? authenticatedOnlyContent() : <Redirect to='/' />}
            </div>
        </Fragment>
    )
}


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
})


export default connect(mapStateToProps, { logout, refresh_token })(Volatility)