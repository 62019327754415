import React, { useState, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { signup } from '../actions/auth'


const Signup = ({ signup, isAuthenticated, signupError, signupSuccessful }) => {
    const [formData, setFormData] = useState({
        email: '',
        password1: '',
        password2: ''
    })

    const { email, password1, password2 } = formData

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = e => {
        e.preventDefault()
        signup(email, password1, password2)
    }

    if (isAuthenticated) {
        return <Redirect to='/' />
    }

    const thereIsSignupError = () => (
        <Fragment>
            <p className='mt-3 text-danger'>{signupError}</p>
        </Fragment>
    )

    const signupSuccess = () => (
        <Fragment>
            <p className='mt-3 text-success'>Signup is successful, please check your email for login information, or sign up for another account.</p>
        </Fragment>
    )

    const blankFragment = () => (
        <Fragment>
        </Fragment>
    )

    return (
        <div className='container my-5 pb-5' style={{maxWidth: "20rem"}}>
            <h1>Sign Up</h1>
            <p>Create your account.</p>
            <form onSubmit={e => onSubmit(e)}>
                <div className='form-group mb-2'>
                </div>
                <div className='form-group mb-2'>
                    <input
                        className='form-control'
                        type='email'
                        placeholder='Email'
                        name='email'
                        value={email}
                        onChange={e => onChange(e)}
                        required
                    />
                </div>
                <div className='form-group mb-2'>
                    <input
                        className='form-control'
                        type='password'
                        placeholder='Password'
                        name='password1'
                        value={password1}
                        onChange={e => onChange(e)}
                        minLength='6'
                        required
                    />
                </div>
                <div className='form-group' mb-3>
                    <input
                        className='form-control'
                        type='password'
                        placeholder='Confirm Password'
                        name='password2'
                        value={password2}
                        onChange={e => onChange(e)}
                        minLength='6'
                        required
                    />
                </div>
                <div className="d-grid">
                    <button className='btn btn-primary mt-3' type='submit'>Register</button>
                </div>
            </form>
            {signupError ? thereIsSignupError() : blankFragment()}
            {signupSuccessful ? signupSuccess() : blankFragment()}
            <p className='mt-3 mb-5'>
                Already have an account? <a href='/login'>Sign in</a>.
            </p>
        </div>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    signupError: state.auth.signupError,
    signupSuccessful: state.auth.signupSuccessful
})

export default connect(mapStateToProps, { signup })(Signup)