import React, { Fragment } from 'react'
import { useLocation } from 'react-router-dom'


const Footer = () => {
    let location = useLocation()
    let displayLocation = ""

    if (location.pathname === "/") {
        displayLocation = "Home"
    } else if (location.pathname === "/login") {
        displayLocation = "Login"
    } else if (location.pathname === "/signup") {
        displayLocation = "Register"
    } else if (location.pathname === "/password-reset") {
        displayLocation = "Password Reset"
    } else if (location.pathname === "//password-reset-confirm/:uid/:token") {
        displayLocation = "Password Reset Confirm"
    } else if (location.pathname === "/retirement") {
        displayLocation = "Retirement"
    } else if (location.pathname === "/savings") {
        displayLocation = "Savings"
    } else if (location.pathname === "/volatility") {
        displayLocation = "Volatility"
    } else {
        displayLocation = "Allocation"
    }

    return (
        <Fragment>
            {/* <footer className="fixed-bottom text-center text-muted py-2" style={{backgroundColor: "white"}}>            */}
            <footer
                className="text-center text-muted py-2 bg-light"
                style={{ marginTop: "auto" }}
            >           
                <nav className="navbar navbar-light navbar-expand py-0">
                    <div class="container-fluid">
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item">
                                <a className="nav-link py-0" href="https://www.facebook.com/cermat.online.financial.planning.education/" target="_blank" rel="noopener noreferrer">
                                    <i className="bi bi-facebook" style={{ fontSize: "2rem" }}></i>
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link py-0" href="https://twitter.com/cermat_online" target="_blank" rel="noopener noreferrer">
                                    <i className="bi bi-twitter" style={{ fontSize: "2rem" }}></i>
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link py-0" href="https://www.linkedin.com/in/cermat-online/" target="_blank" rel="noopener noreferrer">
                                    <i className="bi bi-linkedin" style={{ fontSize: "2rem" }}></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>

                <span className="text-dark">{displayLocation}</span> © 2021 cermat.online
            </footer>
        </Fragment>

    )
}

export default Footer